import React, { useRef,useState } from "react";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { useLocation,useNavigate,Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StandaloneSearchBox,useJsApiLoader } from "@react-google-maps/api";
import Constants from "../services/Constants";
import CategoryComponent from "../components/CategoryComponent";
import RestaurantComponent from "../components/RestaurantComponent";
import Axios from "axios";
import { useForm } from "react-hook-form";
import 'jquery-confirm';

export default function  IndexComponent({noofList,noofItems,device}){
  const location  = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const state = location.state;
  const inputRef = useRef();
  const [loading,setLoading] = useState(0);
  const autoFocus = localStorage.getItem('zoneId')===null ? true : false;
  const { register :register1, handleSubmit:handleSubmit1,setValue : setValue1,getValues : getValues1, formState: { errors : errors1 } } = useForm();
  const { setValue : setValue2 } = useForm();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Constants.REACT_APP_GOOGLE_API_KEY,
    libraries: Constants.REACT_GOOGLE_LIBRARY,
  });

  
  useEffect(() => {
    if(localStorage.getItem('zoneId')==null){
      toast.error("Please Chooose Location",{position: "top-center",closeOnClick: true});
      document.body.style.overflow = "hidden";
    }else document.body.style.overflow = "auto"; 
  },[]);
  
  const handlePlaceChanged = (modal) => { 
      const [ place ] = inputRef.current.getPlaces();
      if(place) {  
        localStorage.setItem('place',JSON.stringify(inputRef.current.getPlaces()));
        localStorage.setItem('latlng','?lat='+place.geometry.location.lat()+'&lng='+place.geometry.location.lng());
        Axios.get(Constants.LOC_API_URL+'?lat='+place.geometry.location.lat()+'&lng='+place.geometry.location.lng()).then((response) => {
          if(response.data){
            console.log('response.data',response.data);
            if(response.data.zone_id) localStorage.setItem('zoneId',response.data.zone_id);
          }
        }).catch((error) => {
          if(error){
            toast.error(t('service_are_not_available'));
            if(modal) setValue2('zone','');
            else setValue1('zone','');
          }
        });
      }
  }



 useEffect(() => {
    setTimeout(() => {
      setLoading(1);
    }, Constants.LOADING_SECONDS);
  },[]);

  useEffect(() => {
    if(state!==null){
      if(state.notify===true){
        if(state.success===true) toast.success(state.message);
        else toast.error(state.message);
        navigate('/', { replace: true });
      }
    }
  },[state,navigate])

 

  const onSubmit1 = (data) => {
    const [ place ] = localStorage.getItem('place')!==null ? JSON.parse(localStorage.getItem('place')) : inputRef.current.getPlaces();
    if(place) {  
      const latlng = localStorage.getItem('latlng')!==null ? localStorage.getItem('latlng') : '?lat='+place.geometry.location.lat()+'&lng='+place.geometry.location.lng();
      Axios.get(Constants.LOC_API_URL+latlng).then((response) => {
        if(response.data){
          if(response.data.zone_id) localStorage.setItem('zoneId',response.data.zone_id);
          localStorage.setItem('userAddress',data.zone);
          navigate('/restaurants');
        }
      }).catch((error) => {
        if(error){
          toast.error(t('service_are_not_available'))
        }
      })
    } 
  }


  return(
    <main>
        <div className="hero_single version_1">
          <div className="opacity-mask">
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-8">
                  <h1>{t('delivery_or_takeaway')}</h1>
                  <p>{t('the_best_restaurant_at_the_best_price')}</p>
                    <form onSubmit={handleSubmit1(onSubmit1)}>
                      <div className="row no-gutters custom-search-input">
                        <div className="col-lg-10">
                          <div className="form-group">
                            {isLoaded && 
                              <StandaloneSearchBox
                                  onLoad={ref => inputRef.current = ref}
                                  onPlacesChanged={() => handlePlaceChanged(0)} >
                                <input 
                                  {...register1("zone", 
                                    { required: true}
                                  )}
                                  defaultValue={localStorage.getItem('userAddress') || "Sangliandapuram, Sangaliandalpuram, Tiruchirappalli, Tamil Nadu, India"}
                                  type="text"
                                  className="form-control no_border_r"
                                  id="location"
                                  autoFocus={autoFocus}
                                  placeholder={t('enter_your_address')}
                                />
                             </StandaloneSearchBox>}
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <button disabled={(getValues1('zone')!=="" || localStorage.getItem("userAddress")!=="") ? false : true} className="btn_1 gradient" type="submit">{t('search')}</button>
                        </div>
                      </div>
                      {errors1.zone?.type==="required" && <p className="error">{t('address_is_required')}</p>}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="wave hero"></div>
          </div>
          <section id="topcategories">
          <div className="container margin_30_60">
            <div className="main_title center">
              <span><em></em></span>
              <h2>{t('popular_categories')}</h2>
              <p>{t('popular_categories_description')}</p>
            </div>
            <CategoryComponent loading={loading} setLoading={setLoading} noofItems={noofItems} device={device} />
          </div>
          </section>
          <section id="toprestaurants">
          <div className="bg_gray">
            <div className="container margin_60_40">
              <div className="main_title">
                <span><em></em></span>
                <h2>{t('top_rated_retaurants')}</h2>
                <p>{t('top_rated_retaurants_description')}</p>
                <Link to="/restaurants">{t('view_all')} &rarr;</Link>
              </div>
              <RestaurantComponent noofList={noofList} device={device} />
            </div>
          </div>
          </section>
          <div className="shape_element_2">
              <div className="container margin_60_0">
                  <div className="row">
                      <div className="col-lg-6">
                          <div className="row">
                              <div className="col-lg-6">
                                  <div className="box_how">
                                    <figure>
                                      <img src={require('../assets/images/how_1.svg').default} alt="" width="150" height="167" className="lazy" />
                                    </figure>
                                    <h3>{t('easy_order')}</h3>
                                    <p>{t('easy_order_desription')}</p>
                                  </div>
                                  <div className="box_how">
                                    <figure>
                                      <img src={require('../assets/images/how_2.svg').default} alt="" width="130" height="145" className="lazy" />
                                    </figure>
                                    <h3>{t('quick_delivery')}</h3>
                                    <p>{t('quick_delivery_description')}</p>
                                  </div>
                              </div>
                              <div className="col-lg-6 align-self-center">
                                <div className="box_how">
                                  <figure>
                                    <img src={require('../assets/images/how_3.svg').default} alt="" width="150" height="132" className="lazy" />
                                  </figure>
                                  <h3>{t('enjoy_food')}</h3>
                                  <p>{t('enjoy_food_description')}</p>
                                </div>
                              </div>
                          </div>
                          <p className="text-center mt-3 d-block d-lg-none">
                          <Link to="/register" className="btn_1 medium gradient pulse_bt mt-2">{t('register_now')}</Link></p>
                      </div>
                      <div className="col-lg-5 offset-lg-1 align-self-center">
                          <div className="intro_txt">
                              <div className="main_title">
                                <span><em></em></span>
                                <h2>{t('start_ordering_now')}</h2>
                              </div>
                              <p className="lead">{t('food_intro_description')}</p>
                              <p>{t('food_para_description')}</p>
                              <div className="row">
                                <div>
                                  <a className="col-md-4" href="https://play.google.com/store/apps/details?id=com.baza.baza_customer" target="_blank">
                                  <img src={require(`../assets/images/androidstore.png`)} /></a>
                                  <a className="col-md-4 mt-2" href="https://apps.apple.com/app/baza-burundi/id1659178968" target="_blank">
                                  <img src={require(`../assets/images/iphonestore.png`)} /></a>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </main>
    )
}