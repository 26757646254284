import React,{ useEffect,useState,useCallback } from "react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import UseAddress from "../hook/UseAddress";
import Constants from "../services/Constants";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Axios from "axios";
import Select from 'react-select';

 function AddToCartComponent({update,setUpdate,sum,resId,itemMenu,setItemMenu,restaurant,showAmount,showVariantAmount}){
  var tagId,attr,isSymbol;
  const [apply,setApply] = useState(false);
  const userToken = localStorage.getItem('userToken');
  const [discount,setDiscount] = useState({amount:0,discount:0});
  const [config] =  useState(localStorage.getItem('web')!==null ? JSON.parse(localStorage.getItem('web')) : 
    {per_km_shipping_charge:0,minimum_shipping_charge:0})
  const resDetail = restaurant.hasOwnProperty("schedule_order") ? restaurant : {delivery_charge:0,schedules:[],schedule_order:false};
  const carts = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
  const [addresses] = userToken ? UseAddress(Constants.GETADDRESS_URL,0) : [[]];    
  const { register, handleSubmit,setValue,clearErrors,getValues,formState : {errors} } = useForm();
  const { register : register1,setValue:setValue1,clearErrors : clearErrors1,getValues:getValues1,setError:setError1,formState : {errors : errors1} } = useForm();
  const getMinutes = s => s.slice(0, 2) * 60 + +s.slice(-2);
  const getTime = m => new Date(m * 6e4).toJSON().slice(11, 16);
  const getTimeSlots = (starts,ends,minDifference,day,schedules) => {
    var result = [];
    if(schedules.hasOwnProperty("opening_time") || schedules.hasOwnProperty("closing_time")){
      var start = getMinutes(starts), end = getMinutes(ends);
      for (var m = start; m <= end - minDifference; m += 30){
        let getHour = getTime(m).split(':');
        let getToday = new Date().toLocaleTimeString('en-US',{hour12: false});
        let getTodayHour = getToday.split(':');
        if(getHour[0]>=parseInt(getTodayHour[0])) {
          if(getHour[0]===parseInt(getTodayHour[0]) && ((getHour[1]==='00' && getTodayHour[1]<='30') || (getHour[1]==='30' && getTodayHour[1]>='30'))){
            if(new Date().getDay()===day) result.push({value:getTime(m) + '-' + getTime(m + minDifference),label:'Now'});
          }else if(getHour[0]>=parseInt(getTodayHour[0])) result.push({value:getTime(m) + '-' + getTime(m + minDifference),label:getTime(m) + '-' + getTime(m + minDifference)});
        }else if(new Date().getDay()!==day) result.push({value:getTime(m) + '-' + getTime(m + minDifference),label:getTime(m) + '-' + getTime(m + minDifference)});
      } 
    }
    return result;
  }

  const setItemQuantity = (itemId,qty) => {
    let filterItem = [];
    if(qty===0){
      if(carts.length > 0) filterItem = carts.filter((item) => item.id!==itemId);
      else if(itemMenu.length > 0) filterItem = itemMenu.filter((item) => item.id!==itemId);
      toast.success('Cart updated successfully');
      if(carts.length > 0 && filterItem.length===0){
        localStorage.setItem('sum',0);
        localStorage.setItem('cart',JSON.stringify(filterItem));
      } 
      setItemMenu(filterItem);
    }else{
      setItemMenu(items =>
        items.map(item => {
          if (item.id === itemId) { return {...item, quantity: qty} }
          return item;
        }),
      );
      itemMenu.map(item => {
          if (item.id === itemId) { return {...item, quantity: qty} }
          return item;
      });
      localStorage.setItem('cart',JSON.stringify(itemMenu));
    }
  }

  
  const handleChangeOrderType = (value) => {
    setUpdate(update + 1);
    setValue('order_type',value);
    setValue('addresses','');
  }

  const handleChangetimeSlot = (e) => {
    setValue('schedule_at',e.value);
    clearErrors('schedule_at');
  }
 
  const handleDeliverCharge = (value) => {
    if(value!==null){
      setValue('addresses',value); 
      let latlng,latitude,longitude,distance,distanceKM= '';
      if(value.length>0)latlng = value.split(',');
      if(latlng!==null){
        latitude = restaurant.hasOwnProperty("latitude") ? restaurant.latitude : 0;
        longitude = restaurant.hasOwnProperty("longitude") ? restaurant.longitude : 0;
        if(latlng.length>=0){
          Axios.get(Constants.DISTANCE_API+'?origin_lat='+latitude+'&origin_lng='+longitude+'&destination_lat='+latlng[0]+'&destination_lng='+latlng[1]
          ).then((response)=>{
            if(response.status===200){
              distance = Math.round(response.data.rows[0].elements[0].distance.value/1000)
              distanceKM = Math.round(response.data.rows[0].elements[0].distance.value/1000) 
              if(restaurant.self_delivery_system) distance = restaurant.free_delivery===true ? 'free' : restaurant.delivery_charge;
              else distance = (distance * config.per_km_shipping_charge > config.minimum_shipping_charge) ? distance * config.per_km_shipping_charge : config.minimum_shipping_charge;
              if(distance>0 || distance!==""){
                setValue('delivery_charge',distance);
                setValue('distance',distanceKM);
              }else{
                toast.error("Please try again later");
              } 
              return true;
            }
          }).catch((error) => {
            console.log(error);
          });
        }
      }else toast.error("Please Choose Address");
    }
  }


  const onSubmit = (data) => { 
    if(getValues1('coupon')!=='') data.coupon_code = getValues1('coupon');
    if(data.order_type==="take_away") data.addresses = '';
    if(itemMenu.length>0){
      Axios.post(Constants.PLACE_ORDER,data,
      {headers: { 'Authorization': 'Bearer '+userToken }}
      ).then((response) => {
        toast.success(response.data.message); 
      }).catch((errors) => {  
        if(errors.response.data.errors.length > 0) toast.error(errors.response.data.errors[0].message);
      }).finally(() => {
      });
    }else toast.error("Please add the items");
  }

  const resetCart = useCallback(() => {
    setValue('cart',JSON.stringify(itemMenu));
    if(itemMenu.length!==carts.length){
      setValue1('coupon','');
      setDiscount({amount:0,discount:0});
      setApply(false);
    } 
  },[itemMenu,carts.length,setValue,setValue1]);

  useEffect(() => {
    resetCart();
  },[resetCart]);

  useEffect(() => {
    if(itemMenu.length > 0){
      localStorage.setItem('cart',JSON.stringify(itemMenu));
      localStorage.setItem('cartRestaurantId',resId);
    } 
  },[tagId,attr,isSymbol,itemMenu,sum,resDetail.delivery_charge,
  resId]);


  const setTimeSlot = (e) => {  
    let value = Number(e.target.value);
    $('#timeslot-'+value).css({'display':'block'});
    $('#timeslot-'+(value+1)).css({'display':'none'});
    $('#timeslot-'+(value-1)).css({'display':'none'});
    setValue('schedule_at','');
  }

  const applyCouponCode = (coupon) => {
    let discountTotAmount,discountAmount = 0;
    if(itemMenu.length===0){
      toast.error("Please add the items");
    }else{
      if(coupon.length===0) setError1("coupon", { type: "required" });
    else clearErrors1('coupon','required')
    if(getValues1('coupon')!==''){
      Axios.get(Constants.APPLY_COUPON_CODE+'?code='+getValues1('coupon')+'&restaurant_id='+restaurant.id,{
         headers: { 'Authorization': 'Bearer '+userToken }
      }).then((response) => {
        if(response){
          if(response.data){
              if (response.data.min_purchase!= null &&  response.data.min_purchase < sum) {
                if (response.data.discount_type === 'percent') {
                  if (response.data.max_discount != null && response.data.max_discount > 0) {
                    discountAmount = ((response.data.discount * sum / 100) < response.data.max_discount)
                        ? response.data.discount : response.data.max_discount;
                    discountTotAmount = ((response.data.discount * sum / 100) < response.data.max_discount)
                        ? (response.data.discount * sum / 100) : (sum - response.data.max_discount); 
                  }
                } else {
                  if (response.data.max_discount != null && response.data.max_discount > 0) {
                    discountAmount = (sum - response.data.discount) < response.data.max_discount
                        ? response.data.discount : response.data.max_discount;
                    discountTotAmount = ((sum - response.data.discount) < response.data.max_discount)
                        ? (sum - response.data.discount) : (sum - response.data.max_discount); 
                  } 
                }
                setApply(true);
                toast.success("Coupon were applied successfully");
                setDiscount({amount:discountTotAmount,discount:discountAmount});
              }else{
                toast.error("Please purchase above "+response.data.min_purchase+" rupees");
              }
            }
        } 
      }).catch((error) => {
        if(error){
          if(error.response){
            if(typeof error.response.data.errors === 'object'){
              var Arr = error.response.data.errors;
              var message = '';
              for (var i = 0; i < Arr.length; i++) { 
                if(Arr[i].code!=='' && Arr[i].message!==''){
                  message = Arr[i].message.charAt(0).toUpperCase() + Arr[i].message.slice(1);
                  toast.error(message); 
                } 
              }
            }
          }
        }
      });
      }
    } 
    
  }


  const handleChangeCoupon = (value) => { 
    setApply(false);
    setDiscount({amount:0,discount:0});
  }
 
  return (
    <div className="col-lg-4 mt-5" id="sidebar_fixed">
      <div className="box_order mobile_fixed">
        <div className="head">
          <h3>Order Summary</h3>
          <a href="#0" className="close_panel_mobile"><i className="icon_close"></i></a>
        </div>
        <div className="main">
          <ul className="clearfix">
            {itemMenu.length > 0 &&
              itemMenu.map((cart,index) => {
                if (cart.name==="") return <></>; 
                return (
                  <li key={index+1}>
                    <div className="row">
                      <Link className="col-md-8" to=""> <i className="icon_minus_alt2 minus-icon" onClick={() => setItemQuantity(cart.id,Number(cart.quantity) - 1)}></i> &nbsp; {cart.quantity} &nbsp; <i className="icon_plus_alt2 plus-icon" onClick={() => setItemQuantity(cart.id,Number(cart.quantity) + 1)}></i>   x {cart.name}</Link> 
                      <span className="col-md-4" id="dataAmount" data-amount={cart.variants.length > 0 ? 0 : parseInt(cart.quantity) * parseInt(cart.price)}>{cart.variants.length > 0 ? '' : showAmount(parseInt(cart.quantity) * parseInt(cart.price))}</span>
                      {cart.variants.length > 0 &&
                        cart.variants.map((variant,index) => {
                        if (!variant.title) return <></>; 
                        return (
                          <div className="ml-2 width-100" key={index+1}>
                            <div className="width-70 float-left">
                            <small key={index+1}>{variant.title} : {variant.type}</small>
                            </div>
                            <div className="width-30 float-right">
                            <small id="dataAmount" data-amount={variant.price}>{showAmount(variant.price)}</small>
                            </div>
                          </div>
                      )})}
                      {cart.add_on_ids.length > 0 &&
                      cart.add_on_ids.map((addon,index) => {
                      return (
                        <div className="ml-2 width-100" key={index+1}>
                          <div className="width-70 float-left">
                          <small key={index+1}>{addon.quantity} x {addon.name}</small>
                          </div>
                          <div className="width-30 float-right ">
                          <small id="dataAmount" data-amount={addon.price * addon.quantity}>{showAmount(addon.price * addon.quantity)}</small>
                          </div>
                        </div>
                      )})}
                    </div>
                  </li>                              
              )})}
          </ul>
          <ul className="clearfix" >
            <li>Subtotal<span>{!isNaN(sum) ? showAmount(sum) : showAmount(0)}</span></li>
            {discount.discount!==0 && <li>Coupon<span> - {discount.discount!==0 ? showAmount(discount.discount) : showAmount(0)}</span></li>}
            {getValues('order_type')==='delivery' ? <li>Delivery fee<span>{typeof getValues('delivery_charge')==="string" ? getValues('delivery_charge').toUpperCase() : '+ '+showAmount(getValues('delivery_charge') ? getValues('delivery_charge') : 0)}</span></li> : ''}
            <li className="total">Total<span>{showAmount((typeof getValues('delivery_charge')==="string" && getValues('delivery_charge')!=="") ? (discount.amount!==0 ? discount.amount : sum) : (discount.amount!==0 ? getValues('delivery_charge')+discount.amount : sum))}</span></li>
          </ul>
         <div className="input-group mb-2">
           <input type="text" 
           {...register1('coupon',{required:true})} onChange={(e) => handleChangeCoupon(e.target.value)} 
           className="form-control" id="apply_promocode" defaultValue="" placeholder="Enter Coupon Code" />
           <div className="input-group-append">
              <button style={{'backgroundColor':`${apply?'limegreen':'red'}`,'color':'#fff'}} 
              onClick={() => applyCouponCode(getValues1('coupon'))} disabled={apply} className="btn_1 gray full-width" type="button" id="button-addon2">
              {`${apply ? "Applied" : "Apply"}`}</button>
            </div>
          </div>
            {errors1.coupon?.type==="required" && <span className="error">Coupon cannot be blank</span>}
          <form id="checkout" onSubmit={handleSubmit(onSubmit)} >
            <input {...register('order_amount',{required:true})} defaultValue={(typeof getValues('delivery_charge')==="string" && getValues('delivery_charge')!=="") ? (discount.amount!==0 ? discount.amount : sum) : (discount.amount!==0 ? getValues('delivery_charge')+discount.amount : sum)} type="hidden"/>
            <input {...register('restaurant_id',{required:true})} defaultValue={resId} type="hidden"/>
            <input {...register('coupon_code')} defaultValue={getValues1('coupon')} type="hidden"/>
            <div className="mb-2">
            <div className="row opt_order">
            <label className="col-12">Preferred Day</label>
            {Constants.ORDER_DAYS_COUNT.length > 0 && 
              Constants.ORDER_DAYS_COUNT.map((order_days,index) => {
              if(new Date().getDay() === order_days.day || new Date().getDay()+1===order_days.day){
                return(
                  <>
                  <div className="col-6" key={index+1}>
                    <label className="container_radio">{`${new Date().getDay() === order_days.day ? 'Today' : 'Tomorrow'}`}
                      <input
                      {...register('order_days',{required:true})} onClick={(e) => setTimeSlot(e)}
                       type="radio" defaultValue={order_days.day} /><span className="checkmark"></span>
                    </label>
                  </div>
                  </>
                )
              }else return <></>
            })} 
            {errors.order_days?.type==="required" && <span className="error ml-3">Order Type cannot be blank</span>}
            </div>
            {resDetail.schedule_order ? 
            <div className="row opt_order">
              {(resDetail!==null && resDetail.schedule_order) ? 
                ((resDetail!==null && resDetail.schedules.length > 0) ?
                resDetail.schedules.map((schedule,index) => {
                  if(new Date().getDay()===schedule.day || new Date().getDay()+1===schedule.day){
                    return (
                      <div key={index+1} className="container" style={{'display' : `${new Date().getDay()===schedule.day ? 'block' : 'none'}`}} id={`timeslot-${schedule.day}`}>
                      <Select {...register('schedule_at',{required:true})} defaultValue={getValues('schedule_at')} onChange={(e) => handleChangetimeSlot(e)} options={getTimeSlots(schedule.opening_time,schedule.closing_time,30,schedule.day,schedule)} key={index+1} ></Select>
                      </div>
                    )}else return <></> }) : '')
                : ''}
                {errors.schedule_at?.type==="required" && <span className="error ml-3">Scheduled Time cannot be blank</span>}
            </div> : ''}
              <div className="row opt_order">
              <label className="col-12">Delivery Type</label>
                {(Constants.ORDER_TYPE.length > 0) &&
                  Constants.ORDER_TYPE.map((order_type,index) => {
                    return(
                      <div className="col-6" key={index+1}>
                        <label className="container_radio">{order_type.charAt(0).toUpperCase()+order_type.slice(1).replace('_',' ')}
                          <input {...register('order_type',{required:true})} onClick={(e) => handleChangeOrderType(order_type)}
                           type="radio" defaultValue={order_type} /><span className="checkmark"></span>
                        </label>
                      </div>
                    )
                })}
                {errors.order_type?.type==="required" && <span className="error ml-3">Delivery Type cannot be blank</span>}
              </div>
            </div>
            {(getValues('order_type')==='delivery' && addresses.length > 0) ? <div style={{'marginTop':'-20px'}}>
              <label className="col-12 p-0 mt-4">Delivery Here</label>
              {addresses.map((address,index) => {
                return(
                <div key={index+1}>
                  <label className="container_radio"><b>{Constants.ADDRESS_TYPES.includes(address.address_type) ? address.address_type.charAt(0).toUpperCase()+address.address_type.slice(1) : ''}</b> - <br />
                  {address.address}
                    <input type="radio" onClick={(e) => handleDeliverCharge(e.target.value)} defaultValue={address.latitude+','+address.longitude+','+address.address_type+','+address.address} {...register('addresses',{required:true})}/>
                    <span className="checkmark"></span>
                  </label>
                </div>
                )
              })}
              {errors.addresses?.type==="required" && <span className="error">Address cannot be blank</span>}
            </div> : (getValues('order_type')==='delivery' && addresses.length===0 ? <div style={{'marginTop':'-20px','marginBottom':'20px'}}>
              <label className="col-12 p-0 mt-4">Delivery Here  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link className="btn_1 gradient" to={'/address'}>Add Address</Link></label>
            </div> : '')}
            <div className="row opt_order">
              <label className="col-12">Delivery Type</label>
                {(Constants.PAYMENT_METHOD.length > 0) &&
                  Constants.PAYMENT_METHOD.map((payment_method,index) => {
                    return(
                      <div className="col-12" key={index+1}>
                        <label className="container_radio">{payment_method.charAt(0).toUpperCase()+payment_method.slice(1).replaceAll('_',' ')}
                          <input {...register('payment_method',{required:true})} type="radio" defaultValue={payment_method} defaultChecked={Constants.PAYMENT_METHOD.length===index+1 ? true : false} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    )
                })}
                {errors.payment_method?.type==="required" && <span className="error ml-3">Delivery Type cannot be blank</span>}
              </div>
              <div className="form-group">
                <label>Add Note</label>
                <textarea className="form-control" {...register('order_note')}  placeholder="Write your review to help others learn about this online business"></textarea>
              </div>
            <div className="btn_1_mobile">
              <button type="submit" className="btn_1 gradient full-width mb_5">Order Now</button>
            </div>
          </form>
        </div>
      </div>
      <div className="btn_reserve_fixed"><a href="#0" className="btn_1 gradient full-width">View Basket</a></div>
    </div>
    )
}


export default AddToCartComponent;