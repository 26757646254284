import { useState, useEffect } from "react";
import Axios from "axios";

const UseCategory = (url) => {
  const [data, setData] = useState([]);

  useEffect(() => {
  	Axios.get(url).then((response)=>{
		  setData(response.data);
  	}).catch((error) => {
      console.log(error)
    });
  }, [url]);

  return [data];
};

export default UseCategory;


