import '../assets/css/timeline.css'
import { Link } from "react-router-dom";
import UseTrackOrder from "../hook/UseTrackOrder";
import Constants from "../services/Constants";
import { useParams } from "react-router-dom";

export default function TrackOrderComponent(){
  const {id} = useParams();
  const [trackOrder] = UseTrackOrder(Constants.GET_TRACKORDER_URL+'?order_id='+id);
  
  return (
      <div className="container margin_60_105">                      
        <div className="row text-center justify-content-center mb-5">
          <div className="box_order_form">
            <div className="head">
              <div className="title row">
                <div className="col-md-6"><h3>Track Order</h3></div>
                <div className="col-md-6 text-right"><h3>#{trackOrder.id}</h3></div>
              </div>
            </div> 
            <div className="main row">
              <div className="col">
                <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
                  <div className="timeline-step">
                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                        <div className="inner-circle"></div>
                        <p className="h6 mt-3 mb-1">Order Placed</p>
                        <p className="h6 text-muted mb-0 mb-lg-0">(pending)</p>
                    </div>
                  </div>
                  <div className="timeline-step">
                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                      <div className="inner-circle"></div>
                      <p className="h6 mt-3 mb-1">Order Confirmed</p>
                      <p className="h6 text-muted mb-0 mb-lg-0">(confirmed)</p>
                    </div>
                  </div>
                  <div className="timeline-step">
                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                      <div className="inner-circle"></div>
                      <p className="h6 mt-3 mb-1">Preparing Food</p>
                      <p className="h6 text-muted mb-0 mb-lg-0">(processing)</p>
                    </div>
                  </div>
                  <div className="timeline-step">
                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                      <div className="inner-circle"></div>
                      <p className="h6 mt-3 mb-1">Food on the way</p>
                      <p className="h6 text-muted mb-0 mb-lg-0">(picked up)</p>
                    </div>
                  </div>
                  <div className="timeline-step mb-0">
                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                      <div className="inner-circle"></div>
                      <p className="h6 mt-3 mb-1">Delivered</p>
                      <p className="h6 text-muted mb-0 mb-lg-0">(delivered)</p>
                    </div>
                  </div>
                </div>
                <div className="mb-4 content-center">
                  <Link to="/orders" className="btn btn-danger ">Back</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}