import '../assets/css/listing.css';
import Constants from "../services/Constants";
import ListSearchComponent from "../components/ListSearchComponent";
import React, { useState } from 'react';
import { useParams } from "react-router-dom";

export default function FoodListingComponent(){
  const [searchResType,setSearchType] = useState("all");
  const { id,catname } = useParams();
	return(
  	<main>
      {!id ? <div className="filters_full clearfix add_bottom_15">
        <div className="container">
          <div className="type_delivery">
            <ul className="clearfix">
              {Constants.RESTAURANT_TYPES.length > 0 &&
                Constants.RESTAURANT_TYPES.map((type,index) => {
                  return(
                    <li key={index+1}>
                      <label className="container_radio">{type.split('_')}
                        <input type="radio" name="type"  checked={searchResType===type.toLowerCase() ? true : false} onChange={() => setSearchType(type.toLowerCase())} value={type.toLowerCase()} id="all"  className="selected" />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    )
                })}
            </ul>
          </div>
        </div>
      </div> : '' }
  		<ListSearchComponent catId={id} catName={catname} type={searchResType} page={id ? 'listing/'+id+'/'+catname : 'listing'} />
  	</main>
	)
}