import { Link,useNavigate,useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants"; 
import Axios from "axios";
import { useEffect,useState } from "react";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import $ from 'jquery'; 

export default function LoginComponent(){
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location  = useLocation();
  const state = location.state;
  const { register, handleSubmit,setValue,setError, formState: { errors } } = useForm();
  const [phone, setPhone] = useState("");
  const [submit,setSubmit] =useState(0);
  
  const onSubmit = (data) => {
    $('#loginForm .spinner').css({'display':'inline-flex'});
    Axios.post(Constants.LOGIN_URL,{
      phone: '+'+data.phone,password : data.password
    }).then((response) => {
      if(response.data.token && response.data.is_phone_verified){
        localStorage.setItem('userToken',response.data.token);
        localStorage.setItem('userVerified',response.data.is_phone_verified);
      }
      if(response.data.is_phone_verified){
        navigate('/account',{state:{notify:true,success:true,message:t('logged_in_successfully')}});
      }else{
        if(response.data.otp) localStorage.setItem('otp',response.data.otp);
        navigate('/verify-phone',{state:{notify:true,success:true,message:t('otp_sent_success')}});
      } 
    }).catch((errors) => {
       if(errors){
        if(typeof errors.response.data.errors === 'object'){
          var Arr = errors.response.data.errors;
          var message = '';
          for (var i = 0; i < Arr.length; i++) { 
            if(Arr[i].code!=='' && Arr[i].message!==''){
              message = Arr[i].message.charAt(0).toUpperCase() + Arr[i].message.slice(1);
              toast.error(message); 
            } 
          }
        }
      }
    }).finally((res) => {
      $('#loginForm .spinner').css({'display':'none'});
    });
  }

  if(submit){
    if(phone.length>0 || phone==='') setValue('phone',phone);
    if(errors.phone?.type==="required" || phone.length===0) setError("phone", { type: "required" });
    setSubmit(0);
  }

  useEffect(() => {
    if(state!==null){
      if(state.notify===true){
        if(state.success===true) toast.success(state.message);
        else toast.error(state.message);
        navigate('/login', { replace: true });
      }
    }
  },[state,navigate])

  return(
      <div id="register_bg">
        <div id="register">
          <aside>
            <figure>
              <Link to="/"><img src={localStorage.getItem('logo')} width="140" height="35" alt="Logo" /></Link>
            </figure>
              <form id="loginForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="form-group">
                  <label>{t('mobile_no')}</label>
                  <input {...register("phone",{required: true})} className="form-control" value={phone || ''} type="hidden" placeholder={t('enter_your_mobile_no')} />
                  <PhoneInput  placeholder={t('enter_your_mobile_no')} enableSearch={true} country={Constants.DEFAULT_PHONE_CODE} value={phone}  onChange={(phone) => setPhone(phone)} />
                  {(errors.phone?.type==="required" && phone.length===0) && <p className="error">{t('mobile_no_is_required')}</p>}
                  {phone.length > 14 && <p className="error">{t('mobile_no_is_maxlength')}</p>}
                  {(phone.length > 0 && phone.length < 11) && <p className="error">{t('mobile_no_is_minlength')}</p>}
                </div>
                <div className="form-group">
                  <label>{t('password')}</label>
                  <input 
                  {...register("password", 
                  { 
                    required: true, 
                    minLength: 6, 
                    maxLength: 15,  
                  })}
                  className="form-control" type="password" id="password" placeholder={t('enter_the_password')} />
                  <i className="icon_lock_alt"></i>
                  {errors.password?.type==="required" && <p className="error">{t('password_is_required')}</p>}
                  {errors.password?.type==="minLength" && <p className="error">{t('password_is_minlength')}</p>}
                  {errors.password?.type==="maxLength" && <p className="error">{t('password_is_maxlength')}</p>}
                </div>
                <div className="clearfix add_bottom_15">
                  <div className="float-right"><Link id="forgot" to="/forgotpassword">{t('forget_password')}</Link></div>
                </div>
                <button onClick={() => setSubmit(1)} type="submit" className="btn_1 content-center gradient full-width">
                <span className="spinner"></span>&nbsp;&nbsp;{t('login_now')}</button>
                <div className="text-center mt-2"><small>{t('dont_have_an_account')} <strong><Link to="/register">{t('sign_up')}</Link></strong></small></div>
                <div className="text-center mt-2 ft-12"><span><strong><Link to="/">&lt;&lt; {t('back_to_home')}</Link></strong></span></div>
              </form>
          </aside>
        </div>
      </div>
  )
}