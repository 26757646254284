import { useState, useEffect } from "react";
import Axios from "axios";

const UseFavourites = (url,update) => {
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem('userToken');
  useEffect(() => {
    Axios.get(url,{
       headers: { 'Authorization': 'Bearer '+userToken,
       'zoneId' : localStorage.getItem('zoneId') }
    }).then((response)=>{
      if(response.data){
        setData(response.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }, [url,userToken,update]);

  return data;
};

export default UseFavourites;