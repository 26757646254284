const CATEGORY_LOADER_DATA = {
  'desktop'     : {'x':280,'y':0,'rx':2,'ry':2,'width':270,'height':200},
  'tablet'      : {'x':275,'y':0,'rx':2,'ry':2,'width':"24%",'height':"100%"},
  'mini-laptop' : {'x':310,'y':0,'rx':2,'ry':2,'width': "32%",'height': "100%"},
  'tab'         : {'x':148,'y':0,'rx':2,'ry':2,'width':"48%",'height': 130},
  'iphone'      : {'x':163,'y':0,'rx':2,'ry':2,'width': 160,'height':"100%"},
  'mobile'      : {'x':280,'y':0,'rx':2,'ry':2,'width':"100%",'height':"100%"}
};

const CATEGORY_SIZE = {
  'desktop':"0 0 1100 220",'tablet':"0 0 1100 200",'min-laptop':"0 0 690 200",
  'tab':"0 0 300 130",'iphone':"0 0 330 200",'mobile':"0 0 285 200"
};


const RESTAURANT_SIZE = {
  'desktop':"0 0 100% 100%",'tablet':"0 0 1100 200",'min-laptop':"0 0 690 200",
  'tab':"0 0 300 130",'iphone':"0 0 330 200",'mobile':"0 0 285 200"
}

const RESTAURANT_DATA = {
  'desktop'     : {'1' : {'x1':"0",'x2':"560",'x3':"200",'x4':"760",'x5':"220",'x6':"780",'x7':"220",'x8':"780",'x9':"220",
                  'y1':"0",'y2':"0",'y3':"0",'y4':"0",'y5':"25",'y6':"25",'y7':"45",'y8':"45",'y9':"65",
                  'rx1':"0",'rx2':"0",'rx3':"0",'rx4':"0",'rx5':"0",'rx6':"0",'rx7':"0",'rx8':"0",'rx9':"0",
                  'ry1':"0",'ry2':"0",'ry3':"0",'ry4':"0",'ry5':"0",'ry6':"0",'ry7':"0",'ry8':"0",'ry9':"0",
                  'w1':"200",'w2':"200",'w3':"340",'w4':"340",'w5':"100",'w6':"100",'w7':"120",'w8':"120",'w9':"160",
                  'h1':"133",'h2':"133",'h3':"2",'h4':"2",'h5':"6",'h6':"6",'h7':"6",'h8':"6",'h9':"6"},
                  '2' : {'x1':"780",'x2':"220",'x3':"780",'x4':"310",'x5':"870",'x6':"200",'x7':"760",'x8':"540",'x9':"1100",
                  'y1':"65",'y2':"85",'y3':"85",'y4':"85",'y5':"85",'y6':"130",'y7':"130",'y8':"0",'y9':"0",
                  'rx1':"0",'rx2':"0",'rx3':"0",'rx4':"0",'rx5':"0",'rx6':"0",'rx7':"0",'rx8':"0",'rx9':"0",
                  'ry1':"0",'ry2':"0",'ry3':"0",'ry4':"0",'ry5':"0",'ry6':"0",'ry7':"0",'ry8':"0",'ry9':"0",
                  'w1':"160",'w2':"80",'w3':"80",'w4':"160",'w5':"160",'w6':"340",'w7':"340",'w8':"2",'w9':"2",
                  'h1':"6",'h2':"20",'h3':"20",'h4':"20",'h5':"20",'h6':"2",'h7':"2",'h8':"130",'h9':"130"} },
};

const Responsive = {
  CATEGORY_LOADER_DATA,CATEGORY_SIZE,RESTAURANT_SIZE,RESTAURANT_DATA
}


export default Responsive;