import React from 'react'
import ContentLoader from 'react-content-loader'

const RestaurantLoaderComponent = props => (
  <ContentLoader width={1140} height={820} viewBox="0 0 1140 820" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} >
    <rect x="15" y="0" rx="2" ry="2" width="350" height="190" />
    <rect x="395" y="0" rx="2" ry="2" width="350" height="190" />
    <rect x="770" y="0" rx="2" ry="2" width="355" height="190" />
    <rect x="15" y="200" rx="2" ry="2" width="350" height="200" />
    <rect x="395" y="200" rx="2" ry="2" width="350" height="200" />
    <rect x="770" y="200" rx="2" ry="2" width="350" height="200" />
    <rect x="15" y="410" rx="2" ry="2" width="350" height="200" />
    <rect x="395" y="410" rx="2" ry="2" width="350" height="200" />
    <rect x="770" y="410" rx="2" ry="2" width="350" height="200" />
    <rect x="15" y="620" rx="2" ry="2" width="350" height="200" />
    <rect x="395" y="620" rx="2" ry="2" width="350" height="200" />
    <rect x="770" y="620" rx="2" ry="2" width="350" height="200" />
  </ContentLoader>
)

export default RestaurantLoaderComponent