import { useLocation } from "react-router-dom";
import $ from 'jquery'; 

export default function ScriptComponent(){
const location = useLocation();
 $('.btn-number').click(function(e){
    e.preventDefault();
    var fieldName = $(this).attr('data-field');
    var type      = $(this).attr('data-type');
    var input = $("input[name='"+fieldName+"']");
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
        if(type === 'minus') {
            
            if(currentVal > input.attr('min')) {
                input.val(currentVal - 1).change();
            } 
            if(parseInt(input.val()) === input.attr('min')) {
                $(this).attr('disabled', true);
            }

        } else if(type === 'plus') {

            if(currentVal < input.attr('max')) {
                input.val(currentVal + 1).change();
            }
            if(parseInt(input.val()) === input.attr('max')) {
                $(this).attr('disabled', true);
            }

        }
    } else {
        input.val(0);
    }
});


    if(location.pathname==='/'){
      $('.black_nav').removeClass("header_in");
      $('.black_nav').addClass("header");
      $(window).on('scroll', function () {
          if ($(this).scrollTop() > 1) {
              $('.element_to_stick').addClass("sticky");
          } else {
              $('.element_to_stick').removeClass("sticky");
          }
      });
      $(window).scroll();
    }else{
      $('.black_nav').removeClass("header");
      $('.black_nav').addClass("header_in");
    }

    $('a.open_close').on("click", function () {
        $('.main-menu').toggleClass('show');
        $('.layer').toggleClass('layer-is-visible');
    });
    $('a.show-submenu').on("click", function () {
        $(this).next().toggleClass("show_normal");
    });

    var $headingFilters = $('.filter_type h4 a');
    $headingFilters.on('click', function () {
        $(this).toggleClass('opened');
    })
    $headingFilters.on('click', function () {
        $(this).toggleClass('closed');
    });

    $('a.open_filters').on("click", function () {
        $('.filter_col').toggleClass('show');
        $('.layer').toggleClass('layer-is-visible');
    });

}