import '../assets/css/detail-page.css';
import { TailSpin } from 'react-loader-spinner'
import React, { useEffect,useState,useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";
import AddToCartComponent from "../components/AddToCartComponent";
import RestaurantDetailComponent from "../components/RestaurantDetailComponent";
import ReviewComponent from "../components/ReviewComponent";
import UseRestaurantDetail from "../hook/UseRestaurantDetail";
import UseRestaurantReviews from "../hook/UseRestaurantReviews";
import UseFavourites from "../hook/UseFavourites";
import UseCategory from "../hook/UseCategory";
import UseProducts from "../hook/UseProducts";
import { useNavigate } from "react-router-dom";
import $ from 'jquery'; 
import Axios from "axios";
import { toast } from 'react-toastify';

export default function DetailComponent({openPage,addDefaultSrc}){
  var newVal,oldValue,inputbtn;
  const navigate = useNavigate();
  let userToken = localStorage.getItem('userToken');
  let cartResId = localStorage.getItem('cartRestaurantId'); 
  const web = JSON.parse(localStorage.getItem('web'));
  const cartShow = JSON.parse(localStorage.getItem('cart'));
  const { id } = useParams();
  const [loading,setLoading] = useState(0);
  const [update,setUpdate] = useState(0);
  const [restaurant] = UseRestaurantDetail(Constants.RESTAURANT_DETAIL_URL+id);
  const [reviews] = UseRestaurantReviews(Constants.RESTAURANT_REVIEW_URL+'?restaurant_id='+id);
  const { t } = useTranslation();
  const [categories] = UseCategory(Constants.CAT_API_URL);
  const [products] = UseProducts(Constants.GETRESTAURANT_PRODUCT_URL+id+'?limit=1000&offset=0'); 
  const favourites = userToken ? UseFavourites(Constants.FAVOURITES_FOOD_RESTAURANT,update) : {'restaurant':[],'food':[]};
  const [itemMenu,setItemMenu] = useState([]);
  const [selected,setSelected] = useState({formId:0,formVariantLength:0});
  const [radioOptions,setRadioOptions] = useState([]);
  var allRadioInputs = radioOptions.length > 0 ? radioOptions : [];
  const addRemoveFavourites = {food_id : 0,restaurant_id : 0};
  const photo = restaurant.hasOwnProperty('cover_photo') ? Constants.RESTAURANT_PHOTO_URL+restaurant.cover_photo : require(`../assets/images/restaurant_cover.png`) 

  const updateCart = useCallback(() => {
    if(cartShow!==null && id===cartResId) cartShow.length > 0 ? setItemMenu(cartShow) : setItemMenu([]);
    else setItemMenu([]);
    if(restaurant!==null){
      if(restaurant.cover_photo!==null) localStorage.setItem('restaurantCoverPhoto',Constants.RESTAURANT_PHOTO_URL+restaurant.cover_photo);
      setLoading(1);
    }
  },[cartResId,cartShow,id,restaurant])

  useEffect(() => {
  },[loading,restaurant,update]);

  useEffect(() =>{
    updateCart();
  },[]);



  const showAmount = (price) => {
    let position = web.currency_symbol_direction;
    if(!isNaN(price)){
      if(position==="left") return web.currency_symbol+" "+price;
      else return price+" "+web.currency_symbol;
    }else{ return ""; }
  }


  function scrollDown(e) {
    e.preventDefault();
    var $target = e.target.getAttribute("href");
    if($target!=="reviews") $('html, body').animate({'scrollTop': $($target).offset().top - 0}, 700, 'swing');
    else $('html, body').animate({'scrollTop': $($target).offset().top - 0 }, 700, 'swing');
  }

  const wishList = (e,type,id,remove) => {
    let url = '';
    if(id){
      if(type===0) addRemoveFavourites.food_id = id;
      else addRemoveFavourites.restaurant_id = id; 
      if(remove===0) url = Constants.FAVOUR_ADD_URL;
      else url = Constants.FAVOUR_REMOVE_URL;
      Axios({
        method : remove===0 ? 'post' : 'delete',
        url : url, data : addRemoveFavourites,
        headers: { 'Authorization': 'Bearer '+userToken } 
      }).then((response) => { if(response.data.message) toast.success(response.data.message);
      }).catch((error) => {
        if(error.response){
          if(error.response.data.message) toast.error(error.response.data.message);
        }
      }).finally((error) => { setUpdate(update+1); })
    }
  }


  function addCalc(e,prodID,isProduct){
    inputbtn = e.target;
    if(inputbtn!==null){
      if(inputbtn.previousSibling) oldValue = parseFloat(inputbtn.previousSibling.value);
      else oldValue = parseFloat(inputbtn.nextSibling.value);
      if(inputbtn.innerHTML === "+"){
        newVal = parseFloat(oldValue) + 1;
        $(`.add-to-cart-${selected.formId}`).find('.'+inputbtn.previousSibling.className).val(newVal);
      }else{
        if (oldValue > 1){
          newVal = parseFloat(oldValue) - 1;
        }else{
          if(!isProduct) newVal = 0;
          else newVal = oldValue
        } 
        $(`.add-to-cart-${selected.formId}`).find('.'+inputbtn.nextSibling.className).val(newVal); 
      }
    }
  }

  const clearAddToCart = () => {
    $.confirm({
      useBootstrap: false,boxWidth: '35%',
      icon: 'fa fa-trash',theme: 'modern',
      scrollToPreviousElement: false,
      title: 'Your cart item will be deleted permanently!',
      content: 'Are you sure to proceed?',
      closeIcon: true,draggable: false,animation: 'scale',
      type: 'red',
      buttons: {
        Ok: {
          text: 'Ok',btnClass: 'btn-red',
          action: function(){
            toast.success("Cart updated successfully");
            localStorage.setItem('cart',JSON.stringify([]));
            return navigate('/detail/'+id);
          }
        },
        close: function () { }
      }
    });
  }

  $(`#add-to-cart-${selected.formId}`).on('submit',function(e){
    if(localStorage.getItem('userToken')!==null){
      e.preventDefault();
      $(`.add-to-cart-${selected.formId} .spinner`).css({'display':'inline-flex'});
      let itemId = selected.formId,price =0,variationLength = 0,variantPrice=0;
      let name ='',concatStr = '';
      let insertItem = {add_on_ids:[],variants:[],add_on_qtys:[]}, filterMenu = [],onlyInputs =[],selectRadio = [],inputAlreadyExist =[];
      variationLength = e.target.getAttribute("data-variation");
      onlyInputs = document.querySelectorAll(`.add-to-cart-${selected.formId} input`);
      onlyInputs.forEach( inputs => {
        if(inputs.type==="text"){
          if(Number(inputs.value)>0){
            price = Number(inputs.getAttribute("data-price"));
            name = inputs.getAttribute("data-name");
            if(inputs.name==='item'){
              if(itemMenu.length > 0) filterMenu = itemMenu.filter((item) => item.id !== Number(inputs.getAttribute("data-id")));
              insertItem = Object.assign(insertItem,{id:itemId,quantity:inputs.value,price:price,name:name});
            }
            if(inputs.name==='addon'){
              insertItem.add_on_ids.push({id:itemId,name:name,price:price,quantity:inputs.value});
              insertItem.add_on_qtys.push(inputs.value);
            } 
          }
        }else{
          if(inputs.type==="hidden") allRadioInputs = inputs.value.length>0 ? JSON.parse(inputs.value) : [];
          for (let a = 0; a < variationLength; a++) {
            if(inputs.name===`variation[${a}]`){
              if(allRadioInputs.length > 0){
                let sortRadioInputs = allRadioInputs.sort(function(a, b) { return (a.sort - b.sort);});
                if(sortRadioInputs.length > 1){
                  [selectRadio] = sortRadioInputs.filter((radio,index) => {
                    return (radio.name === `variation[${a}]` && radio.sort === index+1) && (radio.id === itemId);
                  })
                }else{ [selectRadio] = allRadioInputs;  }
                if(selectRadio && !inputAlreadyExist.includes(`variation[${a}]`)){
                  if(concatStr!=='') concatStr +='-'+selectRadio.value.replaceAll(' ','');
                  else concatStr += selectRadio.value!=='' ? selectRadio.value.replaceAll(' ','') : '';
                  if(concatStr!==''){
                    inputAlreadyExist.push(`variation[${a}]`);
                    variantPrice = showVariantAmount(concatStr,itemId);
                    insertItem.variants.push({title:selectRadio.title,type:concatStr,price:variantPrice});
                  } 
                }
              }
            }
          }
        }
      });
      if(itemMenu.length === 0) setItemMenu([insertItem])
      else{
        if(filterMenu.length < itemMenu.length){
          filterMenu.push(insertItem);
          setItemMenu(filterMenu)
        }else setItemMenu([...itemMenu,insertItem]);
      } 
      setTimeout(() => {
        $(".close").click();
        $(`.add-to-cart-${selected.formId} .spinner`).css({'display':'none'});
        $(`.add-to-cart-${selected.formId}`).trigger('reset'); 
        $(`.add-to-cart-${selected.formId}`).removeClass("submitted");
      },[Constants.MODALCLOSE_SECONDS]);
    }else{
      toast.error('Please Login to continue');
    }
  });



  const clearForm = () => { 
    $(`.add-to-cart-${selected.formId}`).removeClass("submitted");
    $(`.add-to-cart-${selected.formId}`).trigger('reset'); 
    setRadioOptions([]);
  }

  $(`.add-to-cart-${selected.formId} .submit`).click(function(){
    let invalidInputs = $(`.add-to-cart-${selected.formId} input:invalid`);
    if(invalidInputs.length > 0){
      $(`.add-to-cart-${selected.formId}`).addClass("submitted");
    }else{
      $(`.add-to-cart-${selected.formId} input:valid`).removeClass("submitted");
    }
  });


  const showVariantAmount = (variants,prodID) => {
    if(variants!==""){
      let variantsName = '';
      let [choosenProduct] = products.filter((product) => Number(product.id) === Number(prodID));
      if(typeof choosenProduct  === 'object'){
        if(choosenProduct.variations.length > 0){
          for (let i = 0; i < choosenProduct.variations.length; i++) {
            variantsName = variants.trim();
            if(choosenProduct.variations[i].type===variantsName){
              return choosenProduct.variations[i].price;
            }
          }
        }
      }
    }
  }


  const changeRadioInput = (index,inputName,inputValue,inputClass,prodID,inputTitle) => {
    let filteredRadio = [];
    if(radioOptions.length > 0){
       filteredRadio = radioOptions.filter((radio) => { return radio.name !== inputName && radio.value !== inputValue; })
    }
    if(radioOptions.length === 0){
        setRadioOptions([{sort:Number(index),id:prodID,name:inputName,value:inputValue,title:inputTitle}])
    }else{
      if(filteredRadio.length < radioOptions.length){
        filteredRadio.push({sort:Number(index),id:prodID,name:inputName,value:inputValue,title:inputTitle});
        setRadioOptions(filteredRadio)
      }else{
        setRadioOptions([...radioOptions,{sort:Number(index),id:prodID,name:inputName,value:inputValue,title:inputTitle}])
      }
    } 
  }


  const setFormValues = (prodId,prodVariantLength) => {
    $(`.add-to-cart-${selected.formId}`).removeClass("submitted");
    setSelected({formId:prodId,formVariantLength:prodVariantLength});
    setRadioOptions([]);
  }

  let sum = itemMenu.reduce(function(prev, item) {
    let variantsAmount ;
    if(itemMenu.length > 0){
      if(item.variants.length > 0 || item.add_on_ids.length > 0){
        prev += (item.variants.length===0) ? +(item.quantity * item.price) : +item.price;
        if(item.variants.length > 0){
          for (let i = 0; i <= item.variants.length; i++) {
            if(typeof item.variants[i] === 'object'){
              if(item.variants[i].price!=='') variantsAmount = item.variants[i].price;
              if(!isNaN(variantsAmount)) prev += +(item.quantity * variantsAmount);
            }
          }
        }
        if(item.add_on_ids.length > 0){
          for (let i = 0; i <= item.add_on_ids.length; i++) {
            if(typeof item.add_on_ids[i] === 'object'){
              if(!isNaN(item.add_on_ids[i].price) && !isNaN(item.add_on_ids[i].quantity)) prev += +(item.add_on_ids[i].quantity * item.add_on_ids[i].price);
            }
          }
        }
      }else{
        // prev += +item.price;
        prev += (item.variants.length===0) ? +(item.quantity * item.price) : +item.price;
      }
    }
   return prev;
  },0);


	return(
    <main>
      {loading===0 &&
        <div className="container content-center height-full">
          <TailSpin height="50" width="50" color="#f3723b" ariaLabel="tail-spin-loading"
          radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
        </div>}
      {loading===1 && 
        <>
	      <div className="hero_in detail_page background-image" style={{backgroundImage:'url('+photo+')'}}>
	        <div className="wrapper opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
	          <div className="container">
              <div className="main_info">
                <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-6">
                    <div className="head">
                      <div className="score"><span>""<em>
                      {restaurant.hasOwnProperty('count') ? restaurant.count+t('reviews') : '0 '+t('reviews')}
                      </em></span>
                      <strong>{Math.round(restaurant.avg_rating)}</strong></div>
                    </div>
                    <h1>{restaurant.name}</h1>
                    <em>{restaurant.veg ? t('veg') : ''}</em>
                    {restaurant.non_veg && <><em> & {t('non-veg')}</em><br/></>} - {restaurant.address && <>{restaurant.address}<br/></>} - 
                    <a href={Constants.GOOGLE_MAP_URL+`${restaurant.latitude},${restaurant.longitude}`} target="blank">
                    {t('get_direction_on_map')}</a>
                  </div>
                  <div className="col-xl-8 col-lg-7 col-md-6">
                    <div className="buttons clearfix">
                      <span className="magnific-gallery">
                        <a href="#successFullModal1" id="RestaurantImage" data-toggle="modal"  className="btn_hero"><i className="icon_image"></i>View photos</a>
                      </span>
                      {localStorage.getItem('userToken')!==null ? <a href="#0" onClick={(e) => wishList(e,1,id,(favourites.restaurant.includes(id.toString()) ? 1 : 0))} className={`btn_hero wishlist ${favourites.hasOwnProperty("restaurant") ? (favourites.restaurant.includes(id.toString()) ? 'color-red' : '') : ''}`}><i className="icon_heart"></i>Wishlist</a> 
                      :  <a href="#0" onClick={(e) => toast.error('Please login to continue')} className="btn_hero wishlist"><i className="icon_heart"></i>Wishlist</a> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="successFullModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog restaurant-modal" role="document">
            <button type="button" className="close icon-alig-cor white-color" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
            </button>
            <img alt="Restaurant" onError={(e) => addDefaultSrc(e,"restaurant")} src={photo} className="img-fluid img-shadow square" />
          </div>
        </div>
        <nav className="secondary_nav sticky_horizontal ">
          <div className="container">
            <ul id="secondary_nav">
              {categories.length > 0 && 
              categories.map((category,index) => {
               return (restaurant.category_ids && products.length > 0 && restaurant.category_ids.includes(category.id)) ? 
               <li key={index+1}>
                <a onClick={(e) => scrollDown(e)}  className="list-group-item list-group-item-action" href={`#section-${category.id}`}>
               {category.name}</a></li> : false 
              })}
              {products.length===0 && <li><a onClick={(e) => scrollDown(e)} className="list-group-item list-group-item-action" href="#noresultfound"><i className="icon_search"></i>No Results</a></li>}
              <li><a onClick={(e) => scrollDown(e)} className="list-group-item list-group-item-action" href="#reviews"><i className="icon_chat_alt"></i>Reviews</a></li>
            </ul>
          </div>
        <span></span>
        </nav>
      <div className="bg_gray">
        <div className="container margin_detail">
          <div className="row">
            <div className="col-lg-8 list_menu">
              {products.length===0 &&
              <div className="bg-white p-20"><RestaurantDetailComponent/></div>}
              {categories.length > 0 &&  categories.map(function(category,index) {
                return(
                (restaurant.category_ids && restaurant.category_ids.includes(category.id)) ?
                  <section id={`section-${category.id}`} key={index+1}>
                    {products.length > 0 && <h4>{category.name}</h4>}
                    <div className="table_wrapper">
                      <table className="table cart-list menu-gallery">
                        <tbody>
                          {products.length > 0 && products.map(function(product,index){
                            return (product.category_id && product.category_ids.includes(category.id)) ?
                            <tr key={index+1}>
                            <td className="d-md-flex align-items-center">
                              <figure>
                                <a href="#/"  data-effect="mfp-zoom-in">
                                <img onError={(e) => addDefaultSrc(e,"food_small")} src={Constants.PRODUCT_URL+product.image} 
                                alt={product.name} className="rounded-circle img-thumbnail " /></a>
                              </figure>
                              <div className="flex-md-column">
                                <h4>{product.name}</h4>
                                <p>{product.description}</p>
                              </div>
                            </td>
                            <td className="price">
                              <strong>{showAmount(product.price)}</strong>
                            </td>
                            <td className="options">
                              <div className="dropdown dropdown-options d-flex">
                                {localStorage.getItem('userToken')===null &&
                                  <>
                                  <span className="add-cart" onClick={() => toast.error('Please login to continue')}>
                                    <i className="icon_plus_alt2"></i></span>
                                    &nbsp;&nbsp;&nbsp;
                                  <span><i onClick={() => toast.error('Please login to continue')} className="red-color icon_heart_alt"></i></span>
                                  </>
                                }
                                {(localStorage.getItem('userToken')!==null && itemMenu.length===0 && (localStorage.getItem('cart')!==null && JSON.parse(localStorage.getItem('cart')).length > 0)) ?
                                  <>
                                  <span className="add-cart" onClick={() => clearAddToCart()}>
                                    <i className="icon_plus_alt2"></i></span>
                                    &nbsp;&nbsp;&nbsp;
                                  <span><i onClick={(e) => wishList(e,0,product.id,(favourites.food.includes(product.id.toString()) ? 1 : 0))} className={`red-color ${favourites.hasOwnProperty("food") ? (favourites.food.includes(product.id.toString()) ? 'icon_heart' : 'icon_heart_alt') : ''}`}></i></span>
                                  </>
                                  : 
                                  ((localStorage.getItem('userToken')!==null) ?
                                    <>
                                    <span className="add-cart" data-toggle="modal" onClick={() => setFormValues(product.id,product.choice_options.length)} data-target={`#itemCart-${product.id}`}>
                                    <i className="icon_plus_alt2"></i></span>
                                    &nbsp;&nbsp;&nbsp;
                                    <span><i onClick={(e) => wishList(e,0,product.id,(favourites.food.includes(product.id.toString()) ? 1 : 0))} className={`red-color ${favourites.hasOwnProperty("food") ? (favourites.food.includes(product.id.toString()) ? 'icon_heart' : 'icon_heart_alt') : ''}`}></i></span>
                                  </>
                                  : '')
                                }
                                <div className="modal fade gtEditForm" id={`itemCart-${product.id}`} tabIndex="-1" role="dialog" aria-labelledby="aboutInfoModal" aria-hidden="true">
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header text-center">
                                        <div className="col-12">
                                          <h5 className="modal-title" id="exampleModalLabel">Add Item  
                                          <button onClick={() => clearForm()} type="button" className="close closeAboutModal" data-dismiss="modal" aria-label="Close">
                                              <span aria-hidden="true">×</span>
                                          </button>
                                          </h5>
                                        </div>
                                      </div>
                                      <form className={`addTocart add-to-cart-${product.id}`} id={`add-to-cart-${product.id}`} data-variation={product.choice_options.length ? product.choice_options.length : 0} onSubmit={(e) => e.preventDefault()}>      
                                        <div className="modal-body">
                                          <div className="row">
                                            <label className="col-md-6">{t('menu_quantity')}</label>
                                            <div className="numbers-row no-of-item">
                                              {(product.category_id && product.category_ids.includes(category.id)) ? <div className="dec button_inc" onClick={(e) => addCalc(e,product.id,1)} >-</div> : ''}
                                              <input readOnly data-id={product.id} data-price={product.price} data-name={product.name}  
                                              name="item" type="text" defaultValue="1" className={`item-${product.id}`} />
                                              {(product.category_id && product.category_ids.includes(category.id)) ? <div onClick={(e) => addCalc(e,product.id,1)} className="inc button_inc">+</div>: ''}
                                            </div>
                                            {product.add_ons.length > 0 && product.add_ons.map(function(addon,index){
                                              return(
                                              <div className="col-md-4" key={index+1}>
                                                <label>{addon.name.charAt(0).toUpperCase() + addon.name.slice(1)}</label>
                                                <div className="col-md-12 numbers-row" key={index+1} >
                                                  <div onClick={(e) => addCalc(e)} className="dec button_inc">-</div>
                                                    <input data-id={addon.id} data-price={addon.price} data-name={addon.name} name="addon" readOnly 
                                                    type="text" defaultValue="0"  className={`addon-${index+1}`}  />
                                                  <div className="inc button_inc" onClick={(e) => addCalc(e)}>+</div>
                                                </div>
                                              </div>
                                            )})}
                                            <br />
                                            <input type="hidden" value={radioOptions.length > 0 ? JSON.stringify(radioOptions) : []} />
                                            {product.choice_options.length > 0 && product.choice_options.map(function(choices,index){
                                              return(
                                              <div key={index+1} className="width-100">
                                                <label className="container_radio col-md-12">{choices.title}</label>
                                                <br />
                                                {choices.options.length > 0 && choices.options.map(function(option,i){
                                                  return(
                                                    <div className="col-md-6 d-inline-block" key={i+1}>
                                                      <label className="container_radio checkmark-label">{option.charAt(0).toUpperCase() + option.slice(1)}
                                                      <input required={true} name={`variation[${index}]`} onClick={() => changeRadioInput(`${index+1}`,`variation[${index}]`,option,`variation-${i+1}`,product.id,choices.title)}
                                                      className={`variation-${i+1}`} id={`variation[${index}]`} type="radio" value={option} data-title={choices.title} />&nbsp;
                                                      {i===0 && <span className="theTooltip">Required</span>}<span className="checkmark"></span></label>
                                                    </div>
                                                )})}
                                              </div>
                                            )})}
                                            <div className="col-12 modal-footer d-block">
                                              <div className="form-group row text-center">
                                                <button onClick={() => clearForm()} className="col-md-4 btn_1 outline full-width mb-mobile" name="cancel" type="submit" value="Cancel" data-dismiss="modal" aria-label="Close">Cancel</button>   
                                                &nbsp; &nbsp; &nbsp; &nbsp;
                                                <button  className="submit btn_1 col-md-7 full-width" id="btn" name="save" type="submit"><p className="spinner"></p> &nbsp;Add to cart</button> 
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>    
                                    </div>
                                  </div>
                                </div>
                                </div>
                              </td>
                            </tr> : false
                          })}
                        </tbody>
                      </table>
                    </div>
                  </section> : '' )})}
	                </div>
                  <div className="modal fade" id="clearPrevRestaurant" tabIndex="-1" role="dialog" aria-labelledby="clearPrevRestaurant" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header text-center">
                          <div className="col-12">
                            <h5 className="modal-title" id="exampleModalLabel">Add Item  
                              <button onClick={() => clearForm()} type="button" className="close closeAboutModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                              </button>
                            </h5>
                          </div>
                        </div>
                        <div className="modal-body text-center">
                          <div className="row container">
                            <p className="text-center">Are you sure want to reset all the items ? </p>
                          </div>
                        </div>
                        <div className="col-12 modal-footer d-block">
                        </div>
                      </div>
                    </div>
                  </div>
	                <AddToCartComponent update={update} setUpdate={setUpdate} sum={sum} resId={id} itemMenu={itemMenu} setItemMenu={setItemMenu} restaurant={restaurant} showAmount={showAmount} showVariantAmount={showVariantAmount}  />
	              </div>
	            </div>
	          </div>
            <ReviewComponent reviews={reviews} />
          </>
        }
     </main>
	)
}