import { TailSpin } from 'react-loader-spinner'
import React, { useState } from "react";
import { useParams,Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import Constants from "../services/Constants";
import UseOrderDetail from "../hook/UseOrderDetail";

export default function OrderDetailComponent({addDefaultSrc}){
  var tagId,attr;
  const [count,setCount] = useState(0);
  const {id,status} = useParams();
  const [loading,setLoading] = useState(0);
  const [orders] = UseOrderDetail(Constants.ORDERS_DETAIL_API_URL+'?order_id='+id);
  const { t } = useTranslation();
  const web = JSON.parse(localStorage.getItem('web'));
  const orderStatus = window.atob(status);
  
  useEffect(() => {
    if(orders.length > 0){
      setLoading(1);
    }
  },[loading,orders.length]);


  const convertDateString = (date) => {
    let ChangeString = new Date(date);
    return ChangeString.toLocaleDateString('fr-BI', { timeZone: 'UTC' })+" "+ChangeString.toLocaleTimeString('bui');
  }


  const itemTotal = (tagId,attr) => {
    let totalPrice = 0;
    let item = document.querySelectorAll('#'+tagId);
    if(item.length > 0){
      for (let i=0; i < item.length; i++){ 
        let itemPrice = item[i].getAttribute(attr);
        if(tagId==="discount"&& attr==="data-discount") totalPrice += Math.round(itemPrice); 
        else totalPrice += parseInt(itemPrice); 
      }
      return totalPrice;
    }
  }

  const getTax = () => {
    let getSubtotal = 0,getDiscount = 0,getTaxAmount = 0,tax =0;
    getSubtotal = itemTotal("subTotal","data-subtotal"); 
    getDiscount = itemTotal("discount","data-discount");
    tax = orders[0].tax ? orders[0].tax : 0;
    if(tax) getTaxAmount = ((getSubtotal - getDiscount) / 100) * orders[0].tax;
    else getTaxAmount = ((getSubtotal - getDiscount) / 100);
    return getTaxAmount ? Math.round(getTaxAmount) : 0;
  }

  const getTotalAmount = () => {
    let totalAmount = 0,subTotalPrice = 0,discountTotalPrice = 0,taxPrice = 0;
    let sub = document.getElementById('subTotalAmount');
    if(sub!==null) subTotalPrice = sub.getAttribute("data-subtotal-amount");
    if(subTotalPrice) totalAmount += parseInt(subTotalPrice); 
    let discount = document.getElementById('discountAmount');
    if(discount!==null) discountTotalPrice = discount.getAttribute("data-discount-amount");
    if(discountTotalPrice) totalAmount = totalAmount - Math.round(discountTotalPrice);
    let tax = document.getElementById('vatTax');
    if(tax!==null) taxPrice = tax.getAttribute("data-tax");
    if(taxPrice) totalAmount =  totalAmount + Math.round(taxPrice);
    return totalAmount;
  }

  useEffect(() => {
    const interval = setInterval(function () {
      if(count<3){
        setCount(count + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  },[tagId,attr,count]);


  const getCalc = (qty,price) => {
    let sum = 0; sum = qty * price;
    return sum;
  }


  const showAmount = (price) => {
    let position = web.currency_symbol_direction;
    if(position==="left")
     return web.currency_symbol+" "+price;
    else 
     return price+" "+web.currency_symbol;
  }

   const setReviewDetail = () => {
    // localStorage.setItem('reviewImage',Constants.PRODUCT_URL+order.food_details.image)
  }


  return(
      <main>
        {loading===0 &&
        <div className="container content-center height-full">
          <TailSpin height="50" width="50" color="#f3723b" ariaLabel="tail-spin-loading"
          radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
        </div>}
        {loading===1 && 
          <>
          <div className="container margin_60_20 margin-30">
            <div className="row">
              <div className="col-md-12">
               <div className="box_order_form">
                <div className="head">
                 <h3>{t('order_details')}</h3>
                </div>
                <div className="main">
                  <div className="row add_bottom_25">
                    <div className="table-responsive">
                      <div className="list_home">
                        <table className="table table-bordered">
                          {orders.length > 0 && 
                            orders.map((order,index) => {
                              return (
                                <tbody key={index+1}>
                                  {index===0 &&
                                  <> 
                                    <tr className="border-none">
                                    <td className="border-none" colSpan="2">{t('order_id')} #{order.order_id}</td>
                                    <td className="border-none" colSpan="2"></td>
                                    <td className="border-none" colSpan="2">{t('order_type')} : <span className={`badge badge-${Constants.STATUS_COLOR[orderStatus]}`}>{orderStatus.charAt(0).toUpperCase() + orderStatus.slice(1)}</span></td>
                                    <td className="border-none" colSpan="2">Added at <i aria-hidden="true" className="icon-clock_2 fs1"></i> {convertDateString(order.created_at)}</td>
                                  </tr>
                                  <tr>
                                    <td >{t('image')}</td>
                                    <td colSpan="4">{t('item')}</td>
                                    <td colSpan="2">{t('quantity')}</td>
                                    <td className="text-right" colSpan="2">{t('total')}</td>
                                  </tr></>}
                                  {order.food_details && 
                                    <tr>
                                     <td ><img alt={order.food_details.name} onError={(e) => addDefaultSrc(e,"food")} className="rounded-circle img-thumbnail food-wdth" src={order.food_details.image ? Constants.PRODUCT_URL+order.food_details.image : require('../assets/images/no_image_restaurant.jpg')} /></td>
                                     <td colSpan="4">{order.food_details.name}
                                      <p className="d-none" id="discount" data-discount={order.discount_on_food * order.quantity}></p>
                                      <br />
                                      <small>{order.food_details.description}</small>
                                       {order.hasOwnProperty('variation') &&
                                         order.variation.map((variation,index) => {
                                         return(
                                           <div key={index+1}>
                                             <u>{t('variation')} </u>: <small>{variation.type}</small>
                                           </div>
                                         )
                                       })}
                                       <u>{t('price')}</u> : <small>{showAmount(order.price)}</small>
                                       {order.hasOwnProperty('add_ons') > 0 && 
                                         order.add_ons.map((addon,index) => {
                                           return (
                                             <div key={index+1}>
                                               <u>{t('addon')}</u>  :
                                               <p><small id="addonPrice" data-addon-price={addon.price * addon.quantity} >{addon.name} x {addon.quantity} = {showAmount(addon.price)}</small></p>
                                             </div>
                                           )
                                       })}
                                      </td>
                                     <td colSpan="2">{t('item')} x {order.quantity}</td>
                                     <td className="text-right"  id="itemPrice" colSpan="2" data-item-price={order.quantity * order.price}> {showAmount(getCalc(order.quantity,order.price))}
                                     <br />
                                     <br />
                                     {`${Constants.STATUS_COLOR[orderStatus]}`==="success" &&
                                     <Link onClick={() => setReviewDetail()} className="btn-sm btn-success" to={`/review/${order.food_id}`}>Add Review</Link>}
                                   </td>
                                 </tr>}
                                </tbody>
                                )
                              }
                            )
                          }
                          <tbody>
                            <tr>
                              <td colSpan="6"></td>
                              <td>{t('item_price')}</td>
                              <td className="text-right" id="subTotal" data-subtotal={itemTotal("itemPrice","data-item-price")}> {showAmount(itemTotal("itemPrice","data-item-price"))}</td>
                            </tr> 
                            <tr>
                              <td colSpan="6"></td>
                              <td>{t('addon')}</td>
                              <td className="text-right" id="subTotal" data-subtotal={itemTotal("addonPrice","data-addon-price") ? itemTotal("addonPrice","data-addon-price") : 0}>  {showAmount(itemTotal("addonPrice","data-addon-price") ? itemTotal("addonPrice","data-addon-price") : 0)}</td>
                            </tr> 
                            <tr>
                              <td colSpan="6"></td>
                              <td>{t('sub_total')}</td>
                              <td className="text-right" id="subTotalAmount" data-subtotal-amount={itemTotal("subTotal","data-subtotal") ? itemTotal("subTotal","data-subtotal") : 0}> {showAmount(itemTotal("subTotal","data-subtotal"))}</td>
                            </tr> 
                            <tr>
                              <td colSpan="6"></td>
                              <td>{t('discount')}</td>
                              <td className="text-right" id="discountAmount" data-discount-amount={itemTotal("discount","data-discount")}>(-)  {showAmount(itemTotal("discount","data-discount") ? itemTotal("discount","data-discount") : 0)}</td>
                            </tr> 
                            <tr>
                              <td colSpan="6"></td>
                              <td>{t('coupon_discount')}</td>
                              <td className="text-right">(-)  {showAmount(0)}</td>
                            </tr> 
                            <tr>
                              <td colSpan="6"></td>
                              <td>{t('vat_tax')}</td>
                              <td className="text-right" id="vatTax" data-tax={getTax() ? getTax() : 0}>(+)  {showAmount(getTax() ? getTax() : 0)}</td>
                            </tr> 
                            <tr>
                              <td colSpan="6"></td>
                              <td>{t('delivery_fees')}</td>
                              <td className="text-right">(+)  {showAmount(0)}</td>
                            </tr> 
                            <tr>
                              <td colSpan="6"></td>
                              <td>{t('total')}</td>
                              <td className="text-right"> {showAmount(getTotalAmount() ? getTotalAmount() : 0)}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="content-center">
                          <Link to="/orders" className="btn btn-danger ">Back</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      }
      </main>
    )
}