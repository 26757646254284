import React from 'react'
import ContentLoader from 'react-content-loader'

const RestaurantDetailComponent = props => {
  return (
    <ContentLoader height={520} width={730} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} >
      <circle cx="40" cy="50" r="25" />
      <rect x="80" y="40" rx="5" ry="5" width="350" height="15" />
      <rect x="550" y="40" rx="5" ry="5" width="100" height="15" />
      <rect x="670" y="40" rx="5" ry="5" width="20" height="15" />
      <rect x="700" y="40" rx="5" ry="5" width="20" height="15" />
      <rect x="0" y="90" rx="0" ry="0" width="730" height="2" />
      <circle cx="40" cy="130" r="25" />
      <rect x="80" y="120" rx="5" ry="5" width="350" height="15" />
      <rect x="550" y="120" rx="5" ry="5" width="100" height="15" />
      <rect x="670" y="120" rx="5" ry="5" width="20" height="15" />
      <rect x="700" y="120" rx="5" ry="5" width="20" height="15" />
      <rect x="0" y="170" rx="0" ry="0" width="730" height="2" />
      <circle cx="40" cy="210" r="25" />
      <rect x="80" y="200" rx="5" ry="5" width="350" height="15" />
      <rect x="550" y="200" rx="5" ry="5" width="100" height="15" />
      <rect x="670" y="200" rx="5" ry="5" width="20" height="15" />
      <rect x="700" y="200" rx="5" ry="5" width="20" height="15" />
      <rect x="0" y="250" rx="0" ry="0" width="730" height="2" />
      <circle cx="40" cy="290" r="25" />
      <rect x="80" y="280" rx="5" ry="5" width="350" height="15" />
      <rect x="550" y="280" rx="5" ry="5" width="100" height="15" />
      <rect x="670" y="280" rx="5" ry="5" width="20" height="15" />
      <rect x="700" y="280" rx="5" ry="5" width="20" height="15" />
      <rect x="0" y="340" rx="0" ry="0" width="730" height="2" />
      <circle cx="40" cy="380" r="25" />
      <rect x="80" y="370" rx="5" ry="5" width="350" height="15" />
      <rect x="550" y="370" rx="5" ry="5" width="100" height="15" />
      <rect x="670" y="370" rx="5" ry="5" width="20" height="15" />
      <rect x="700" y="370" rx="5" ry="5" width="20" height="15" />
      <rect x="0" y="430" rx="0" ry="0" width="730" height="2" />
      <circle cx="40" cy="470" r="25" />
      <rect x="80" y="460" rx="5" ry="5" width="350" height="15" />
      <rect x="550" y="460" rx="5" ry="5" width="100" height="15" />
      <rect x="670" y="460" rx="5" ry="5" width="20" height="15" />
      <rect x="700" y="460" rx="5" ry="5" width="20" height="15" />
      <rect x="0" y="340" rx="0" ry="0" width="730" height="2" />
    </ContentLoader>
  )
}

export default RestaurantDetailComponent