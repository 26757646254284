import React, { useState } from "react";
import { useEffect } from "react";
import Constants from "../services/Constants";
import ListRestaurantComponent from "../components/ListRestaurantComponent";

export default function RestaurantsListing(){
   const [searchResType,setSearchType] = useState("all");
   useEffect(() => {document.body.style.overflow = "auto"; 
    },[])
 
  return (
    <>
    <div className="filters_full clearfix add_bottom_15">
        <div className="container">
          <div className="type_delivery">
            <ul className="clearfix">
              {Constants.RESTAURANT_TYPES.length > 0 &&
                Constants.RESTAURANT_TYPES.map((type,index) => {
                  return(
                    <li key={index+1}>
                      <label className="container_radio">{type.split('_')}
                        <input type="radio" name="type"  checked={searchResType===type.toLowerCase() ? true : false} onChange={() => setSearchType(type.toLowerCase())} value={type.toLowerCase()} id="all"  className="selected" />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                    )
                })}
            </ul>
          </div>
        </div>
      </div>
    <ListRestaurantComponent type={searchResType} />
    </>
  )
}