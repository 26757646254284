import React ,{ useEffect,useState } from "react";
import ContentLoader from 'react-content-loader';
import Responsive from "../services/Responsive";

function CategoryLoaderComponent({noofItems,device}){
  const [config,setConfig] = useState({noofItems:4,device:'desktop'});
  useEffect(() => { setConfig({noofItems:noofItems,device:device}) },[noofItems,device]);
  
  return(
    <ContentLoader width="100%" height="100%" viewBox={Responsive.CATEGORY_SIZE[device]}  backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
    {[...Array(config.noofItems)].map((arr, i) => { 
      return <rect key={i+1} x={i===0 ? 0 : i * Responsive.CATEGORY_LOADER_DATA[device].x+5} 
              y={Responsive.CATEGORY_LOADER_DATA[device].y} 
              rx={Responsive.CATEGORY_LOADER_DATA[device].rx} 
              ry={Responsive.CATEGORY_LOADER_DATA[device].ry}
              width={Responsive.CATEGORY_LOADER_DATA[device].width} 
               height={Responsive.CATEGORY_LOADER_DATA[device].height} 
               />
    })}
    </ContentLoader>
  )
  
}
  

export default CategoryLoaderComponent




