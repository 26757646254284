import { useState, useEffect } from "react";
import Axios from "axios";

const UseOrderRunning = (url) => {
  const [data, setData] = useState({total_size:0,limit:10,offset:0,orders:[]});
  const userToken = localStorage.getItem('userToken');
  useEffect(() => {
    Axios.get(url,{
       headers: { 'Authorization': 'Bearer '+userToken }
    }).then((response)=>{
      if(response.data.orders){
        setData(response.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }, [url,userToken]);

  return data;
};

export default UseOrderRunning;