import { TailSpin } from 'react-loader-spinner'
import React, { useState,useCallback } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { toast } from 'react-toastify';
import Constants from "../services/Constants";
import { useForm } from "react-hook-form";
import Axios from "axios";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default  function MyAccountComponent({addDefaultSrc}){
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location  = useLocation();
  const state = location.state;
  let userToken = localStorage.getItem('userToken');
  const initialState = { f_name: "",l_name: "",email: "",image: "",phone:"",password:""};
  const [user,setUser] = useState(initialState);
  const { register, handleSubmit,setValue,setError, formState: { errors } } = useForm();
  const [imgData, setImgData] = useState(localStorage.getItem('userImage'));
  const [picture, setPicture] = useState(null);
  const [loading,setLoading] = useState(0);
  const [phone, setPhone] = useState("");
  const [submit,setSubmit] =useState(0);

  useEffect(() => {
    if(state!==null){
      if(state.notify===true){
        if(state.success===true) toast.success(state.message);
        else toast.error(state.message);
        navigate('/account', { replace: true });
      }
    }
  },[state,navigate])

  const getUserData = useCallback(() => {
    if(loading===0){
      Axios.get(Constants.USERINFO_API_URL,{ 
        headers: { 'Authorization': 'Bearer '+userToken }
      }).then((response)=>{
        if(response.data){
          setLoading(1); 
          setUser(response.data);
          if(response.data.image!==null) 
            localStorage.setItem('userImage',Constants.CUSTOMER_URL+response.data.image);
            setImgData(Constants.CUSTOMER_URL+response.data.image);
          if(response.data.f_name!=='' && response.data.f_name.length>10)
            localStorage.setItem('userName',response.data.f_name.substring(0,5)+'...');
          else
            localStorage.setItem('userName',response.data.f_name);
        }
      }).catch((error) => {
        setLoading(1);
        console.log('usererr',error)
      });
    }
  },[loading,userToken])
  

  useEffect(() => {
    getUserData();
  }, [getUserData]);

   const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  

  const onSubmit = (data) => {
    Axios.post(Constants.UPDATE_INFO_API_URL,{
      f_name : data.f_name,l_name:data.l_name,
      email : data.email,image : picture,
      password : user.password,phone: (user.phone==='+') ? user.phone : '+'+phone 
    },{ headers : {
      Authorization : 'Bearer '+userToken,
      "Content-Type": "multipart/form-data" 
    }
    }).then((response) => {
      if(response.data){
        toast.success(response.data.message);
      }
    }).catch((errors) => {
      console.log('error',errors);
      if(errors){
        if(typeof errors.response.data.errors === 'object'){
          var Arr = errors.response.data.errors;
          var message = '';
          for (var i = 0; i < Arr.length; i++) { 
            if(Arr[i].code!=='auth-001' && Arr[i].message!=='Unauthorized'){
              message = Arr[i].message.charAt(0).toUpperCase() + Arr[i].message.slice(1);
              toast.error(message); 
            }else toast.error(t('invalid_username_or_password')); 
          }
        }
      }
    }).finally(() => {
    });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };


  if(submit){
    if(phone.length>0) setValue('phone',phone);
    if(errors.phone?.type==="required") setError("phone", { type: "required" }, { shouldFocus: true });
    setSubmit(0);
  }

  return(
    <main>
    {loading===0 &&
      <div className="container content-center height-full">
        <TailSpin height="50" width="50" color="#f3723b" ariaLabel="tail-spin-loading"
        radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
      </div>}
    {loading===1 && 
      <>
      <div className="container margin_60_20 margin-30">
        <div className="row">
          <div className="col-md-12">
            <div className="box_order_form">
              <div className="head">
                <div className="title">
                  <h3>{t('my_account')}</h3>
                </div>
              </div> 
              <div className="main">
                <form id="customer-form" onSubmit={handleSubmit(onSubmit)}>  
                <div className="row">       
                <div className="form-group col-md-6">
                  <label>{t('first_name')}<span className="required"> *</span></label>
                  <input 
                    {...register("f_name", 
                      { required: true,
                        pattern: /^[A-Za-z\s]*$/,
                        maxLength: 16, 
                        minLength: 3,
                        onChange : (e) => handleInputChange(e)
                      }
                    )}
                    className="form-control" defaultValue={user.f_name || ''}  type="text" placeholder={t('enter_your_first_name')} />                  
                  {errors.f_name?.type==="required" && <p className="error">{t('firstname_is_required')}</p>}
                  {errors.f_name?.type==="maxLength" && <p className="error">{t('firstname_is_maxlength')}</p>}                   
                  {errors.f_name?.type==="pattern" && <p className="error">{t('firstname_is_invalid')}</p>}                   
                  {errors.f_name?.type==="minLength" && <p className="error">{t('firstname_is_minlength')}</p>}
                </div>
                <div className="form-group col-md-6">
                  <label>{t('last_name')}<span className="required"> *</span></label>
                  <input 
                  {...register("l_name", 
                    { 
                      required: true, 
                      pattern: /^[A-Za-z\s]*$/,
                      maxLength: 16, 
                      minLength: 3,
                      onChange : (e) => handleInputChange(e)
                    }
                  )}
                  className="form-control" defaultValue={user.l_name || ''} type="text" placeholder={t('enter_your_last_name')} />
                </div>
                </div>
                <div className="row">
                <div className="form-group col-md-6">
                  <label>{t('mobile')} <span className="required">*</span></label>
                  <input {...register("phone",{required: true})}
                  className="form-control" defaultValue={user.phone || phone} type="hidden" placeholder={t('enter_your_mobile_no')} />
                  <PhoneInput  placeholder={t('enter_your_mobile_no')} enableSearch={true} country={Constants.DEFAULT_PHONE_CODE} value={user.phone || phone}  onChange={(phone) => setPhone(phone)} />
                  {(errors.phone?.type==="required" && phone.length===0) && <p className="error">{t('phone_no_is_required')}</p>}
                  {phone.length > 14 && <p className="error">{t('phone_no_is_maxlength')}</p>}
                  {(phone.length > 0 && phone.length < 11) && <p className="error">{t('phone_no_is_minlength')}</p>}
                </div>
                <div className="form-group col-md-6">
                  <label>{t('email')}<span className="required"> *</span></label>
                  <input
                  {...register("email")}  
                   placeholder={t('enter_your_email_address')} defaultValue={user.email || ''} className="form-control" type="text"  />                  
                </div>
                </div>
                <div className="row mt-4">
                  <div className="form-group col-sm-6">
                    <label>{t('password')}<span className="required"> *</span></label>      
                    <input
                    {...register("password", 
                      { 
                        minLength: 6, 
                        maxLength: 15,  
                        onChange : (e) => handleInputChange(e)
                      }
                    )}
                    placeholder={t('enter_your_password')} defaultValue={user.password || ''} className="form-control" type="text"  />     
                    {errors.password?.type==="required" && <p className="error">{t('password_is_required')}</p>}
                    {errors.password?.type==="minLength" && <p className="error">{t('password_is_minlength')}</p>}
                    {errors.password?.type==="maxLength" && <p className="error">{t('password_is_maxlength')}</p>}                            
                  </div>
                  <div className="col-sm-4">
                    <label>{t('profile_picture')}</label><br/>
                    <label htmlFor="file-upload" className="custom-file-upload">
                        <i className="fa fa-cloud-upload"></i> Browse Image
                    </label>
                    <input onChange={onChangePicture} accept="image/*" size="60" maxLength="100" id="file-upload" type="file"/>
                  </div>
                  <div className="col-sm-2">
                    <figure>
                      <img onError={(e) => addDefaultSrc(e,"")} src={imgData ? imgData : require('../assets/images/no_image.png')} alt="Profile" className="rounded-circle img-thumbnail equal"/>
                    </figure>
                  </div>
                  </div>
                  <div className="form-group">
                    <button onClick={() => setSubmit(1)} type="submit" className="btn_1 gradient full-width height-half">{t('update')}</button>
                  </div>
                </form>     
              </div>
            </div>
          </div>
        </div>
      </div>
      </> }
    </main>
    )
}