import { StandaloneSearchBox,GoogleMap, LoadScript } from "@react-google-maps/api";
import React, { useRef,useState } from "react";
  import Constants from "../services/Constants";
export default function StandAloneBox(){
  const inputRef = useRef();
  const [place,setPlace] = useState({lat:38.685,lng: -115.234})
  const mapContainerStyle = {
    height: "400px",
    width: "800px",
    margin :"100px"
  };

  const center = place;

   const onPlacesChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) {  
      setPlace({lat:place.geometry.location.lat(),lng:place.geometry.location.lng(),zoom:15});
    } 
  }

  return (
      <LoadScript googleMapsApiKey={Constants.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
        <GoogleMap
          id="searchbox-example"
          mapContainerStyle={mapContainerStyle}
          zoom={place.zoom ? place.zoom : 2.5}
          center={center}
        >
          <StandaloneSearchBox
            onLoad={ref => inputRef.current = ref}
            onPlacesChanged={
              onPlacesChanged
            }
          >
            <input
              type="text"
              placeholder="Customized your placeholder"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px"
              }}
            />
          </StandaloneSearchBox>
        </GoogleMap>
      </LoadScript>
    )
}