import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';


export default function ContactComponent(){
	const { t } = useTranslation();
	const { register, handleSubmit,setValue, formState: { errors } } = useForm();
	const onSubmit = (data) => {}

	return(
		<div id="register_bg nobg-image" style={{'background': 'url("")!important'}}>
			<div id="register">
				<aside>
					<h3 className="text-center">Contact Us</h3>
					<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<div className="form-group">
							<input 
							{...register("f_name", 
								{ required: true,
									pattern: /^[A-Za-z\s]*$/,
                  maxLength: 32, 
                }
              )}
							className="form-control" type="text" placeholder={t('enter_your_first_name')} />
							<i className="icon_pencil-edit"></i>
							{errors.f_name?.type==="required" && <p className="error">{t('firstname_is_required')}</p>}
							{errors.f_name?.type==="maxLength" && <p className="error">{t('firstname_is_maxlength')}</p>}                 	
        			{errors.f_name?.type==="pattern" && <p className="error">{t('firstname_is_invalid')}</p>}                 	
						</div>
						<div className="form-group">
							<input 
							{...register("l_name", 
								{ 
									required: true, 
                  pattern: /^[A-Za-z\s]*$/,
                  maxLength: 32, 
                }
              )}
							className="form-control" type="text" placeholder={t('enter_your_last_name')} />
							<i className="icon_pencil-edit"></i>
							{errors.l_name?.type==="required" && <p className="error">{t('lastname_is_required')}</p>}
							{errors.l_name?.type==="maxLength" && <p className="error">{t('lastname_is_maxlength')}</p>}                 	
      				{errors.l_name?.type==="pattern" && <p className="error">{t('lastname_is_invalid')}</p>}                 
						</div>
						<div className="form-group">
							<input
							{...register("email", 
							{ 	required : true,
									pattern: {
										value : /^\S+@\S+$/i,
									}
							})}
							 className="form-control" type="text" placeholder={t('enter_your_email_address')} />
							<i className="icon_mail_alt"></i>
							{errors.email?.type==="required" && <p className="error">{t('email_is_required')}</p>}
							{errors.email?.type==="pattern" && <p className="error">{t('email_is_invalid')}</p>}                 	          
      				{errors.email?.type==="validate" && <p className="error">{t('email_is_validate')}</p>}                 	          
						</div>
						<div className="form-group">
							<input
							{...register("phone", 
								{
                  required: true, 
                  maxLength: 11,
                  onChange :(e) => setValue('phone',e.target.value.replace(/\D/g,'')),
                  minLength: 10,
                 }
               )}
							 className="form-control" type="text" id="password1" placeholder={t('enter_your_mobile_no')} />
							<i className="icon_pencil-edit"></i>
							{errors.phone?.type==="required" && <p className="error">{t('mobile_is_required')}</p>}
              {errors.phone?.type==="maxLength" && <p className="error">{t('mobile_is_maxlength')}</p>}
              {errors.phone?.type==="minLength" && <p className="error">{t('mobile_is_minlength')}</p>}
						</div>
						<div className="form-group">
							<input
							{...register("password", 
								{ 
									required: true, 
                  minLength: 3, 
                  maxLength: 15,  
                }
              )}
							 className="form-control" type="password" id="password2" placeholder={t('enter_your_password')} />
							<i className="icon_lock_alt"></i>
							{errors.password?.type==="required" && <p className="error">{t('password_is_required')}</p>}
              {errors.password?.type==="minLength" && <p className="error">{t('password_is_minlength')}</p>}
              {errors.password?.type==="maxLength" && <p className="error">{t('password_is_maxlength')}</p>}
						</div>
						<div id="pass-info" className="clearfix"></div>
						<button className="btn_1 gradient full-width">{t('register_now')}</button>
						<div className="text-center mt-2"><small>{t('already_have_an_account')}<strong>
							<Link to="/login">{t('sign_in')}</Link></strong></small>
						</div>
					</form>
				</aside>
			</div>
		</div>
  )
}