import React ,{ useEffect,useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { Link } from "react-router-dom";
import Constants from "../services/Constants";
import UseCategory from "../hook/UseCategory";
import CategoryLoaderComponent from "../components/CategoryLoaderComponent";

export default function CategoryComponent({loading,setLoading,noofItems,device}){
  const zoneId = localStorage.getItem('zoneId');
  const [categories] = zoneId!=null ? UseCategory(Constants.CAT_API_URL) : [[]];
  const [image,setImage] = useState({width:'200',height:'200',fixed:0});
  const imageSize = {width:'100',height:'100',fixed:1};

  useEffect(() => { setLoading(1)  },[categories,setLoading,noofItems,device])

  const addDefaultSrc = (e) => {
    e.target.src = require('../assets/images/no_image_food.jpg');
    if(image.fixed===0) setImage(imageSize);
  }


  return(
    <>
      {categories.length > 0 ?
        <OwlCarousel className="owl-theme" items={noofItems} autoplay dots loop>
          {categories.map((category,index) => {
            return (
              <div key={index+1} className="item_version_2 mr-2">
              <Link to={`/listing/${category.id}/${window.btoa(category.name)}`}>
                <figure>
                    <img onError={addDefaultSrc} src={Constants.CATEGORY_URL+category.image} 
                    alt={category.name} width={image.width} height={image.height} />
                    <div className="info">
                      <h3>{category.name}</h3>
                    </div>
                </figure>
              </Link>
            </div>
            )
          })}
        </OwlCarousel>
      : <CategoryLoaderComponent noofItems={noofItems} device={device} />}
    </>
  )
}
