import { useLocation,useNavigate,Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants"; 
import Axios from "axios";
import { useEffect,useState } from "react";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import $ from 'jquery'; 

export default function LoginComponent(){
  const { t } = useTranslation();
  const location  = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const { register, handleSubmit,setValue,setError, formState: { errors } } = useForm();
  const [phone, setPhone] = useState("");
  const [submit,setSubmit] =useState(0);

  const onSubmit = (data) => {
    $('.spinner').css({'display':'inline-flex'});
    Axios.post(Constants.VERIFY_PHONE_URL,{
      phone: '+'+data.phone,otp : data.otp
    }).then((response) => {
      if(response.data){
        navigate('/login',{state:{notify:true,success:true,message:t(response.data.message)}});
      }
    }).catch((errors) => {
      if(errors){
        if(errors.response.data.message!==''){
          toast.error(errors.response.data.message);
        }
        if(typeof errors.response.data.errors === 'object'){
          var Arr = errors.response.data.errors;
          for (var i = 0; i < Arr.length; i++) { 
            if(Arr[i].code!=='' && Arr[i].message!==''){
              var message = Arr[i].message.charAt(0).toUpperCase() + Arr[i].message.slice(1);
              toast.error(message); 
            }
          }
        }
      }
    }).finally((res) => {
       $('.spinner').css({'display':'none'});
    });
  }

  if(submit){
    if(phone.length>0 || phone==='') setValue('phone',phone);
    if(errors.phone?.type==="required" || phone.length===0) setError("phone", { type: "required" });
    setSubmit(0);
  }

   useEffect(() => {
    if(state!==null){
      if(state.notify===true){
        if(state.success===true) toast.success(state.message);
        else toast.error(state.message);
        navigate('/verify-phone', { replace: true });
      }
    }
  },[state,navigate])


  return(
    <div id="register_bg">
      <div id="register">
        <aside>
          <figure>
            <Link to="/"><img src={localStorage.getItem('logo')} width="140" height="35" alt="Logo" /></Link>
          </figure>
            <h5 className="text-center">{localStorage.getItem('otp')!==null && `Your otp is : ${localStorage.getItem('otp')}`}</h5>
            <form onSubmit={handleSubmit(onSubmit)} >
              <div className="form-group">
                <label>{t('mobile_no')}</label>
                <input {...register("phone",{required: true})} className="form-control" value={phone || ''} type="hidden" placeholder={t('enter_your_mobile_no')} />
                <PhoneInput  placeholder={t('enter_your_mobile_no')} enableSearch={true} country={Constants.DEFAULT_PHONE_CODE} value={phone}  onChange={(phone) => setPhone(phone)} />
                {(errors.phone?.type==="required" && phone.length===0) && <p className="error">{t('mobile_no_is_required')}</p>}
                {phone.length > 14 && <p className="error">{t('mobile_no_is_maxlength')}</p>}
                {(phone.length > 0 && phone.length < 11) && <p className="error">{t('mobile_no_is_minlength')}</p>}
              </div>
              <div className="form-group">
                <label>{t('otp')}</label>
                <input 
                {...register("otp", 
                { required: true,
                  minLength : 4,
                  maxLength : 4,
                  onChange :(e) => setValue('otp',e.target.value.replace(/\D/g,'')),
                 })}
                className="form-control" type="text" placeholder={t('enter_your_otp')} />
                <i className="icon_lock_alt"></i>
                {errors.otp?.type==="required" && <p className="error">{t('otp_is_required')}</p>}
                {errors.otp?.type==="maxLength" && <p className="error">{t('otp_is_maxlength')}</p>}
                {errors.otp?.type==="minLength" && <p className="error">{t('otp_is_minlength')}</p>}
              </div>
              <div className="clearfix add_bottom_15">
              </div>
              <button onClick={() => setSubmit(1)} type="submit" className="btn_1 gradient full-width"><span className="spinner"></span>&nbsp;&nbsp;{t('submit')}</button>
              <div className="text-center mt-2"><small>{t('dont_have_an_account')} <strong><Link to="/register">{t('sign_up')}</Link></strong></small></div>
              <div className="text-center mt-2 ft-12"><span><strong><Link to="/">&lt;&lt; {t('back_to_home')}</Link></strong></span></div>
            </form>
        </aside>
      </div>
    </div>
    )
}