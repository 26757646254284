import {useEffect} from 'react';

export default function IframeComponent({lat,lng,zoom}){

  useEffect(()=>{
    const ifameData=document.getElementById("iframeId")
    if(lat && lng && zoom)
    ifameData.src=`https://maps.google.com/maps?q=${lat},${lng}&hl=es;&z=${zoom}&amp;&output=embed`
  },[lat,lng,zoom]);

  return(
    <iframe id="iframeId" className="width-100" title="Map" frameBorder="2" loading="lazy" 
      allowFullScreen >
    </iframe>
  )
}