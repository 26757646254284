import { Link } from "react-router-dom";
import Constants from "../services/Constants";

export default function FooterComponent(){
    function alignString(str) {
      var i, frags = str.split('-');
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }

    const onChange = () => {};
    
    return(
      <>
        <footer>
          <div className="wave footer"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ul className="footer-selector clearfix">
                  <li>
                    <div className="styled-select lang-selector d-none">
                      <select onChange={ onChange }>
                        <option defaultValue="French">French</option>
                        <option defaultValue="Spanish">Spanish</option>
                        <option defaultValue="Russian">Russian</option>
                      </select>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className="styled-select currency-selector">
                      <select onChange={ onChange }>
                        <option defaultValue="Euro">Euro</option>
                      </select>
                    </div>
                  </li>
                  <li><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="img/cards_all.svg" alt="" width="230" height="35" className="lazy" /></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="additional_links">
                  {Constants.FOOTER_MENU.map((menu,index) => {
                    return <li key={index+1}><Link to={menu}>{alignString(menu)}</Link></li>
                  })}
                  <li><span>© CopyRight </span></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
}