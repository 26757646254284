import { useState, useEffect } from "react";
import Axios from "axios";

const UseRestaurantReviews = (url) => {
  const [data, setData] = useState([]);
  const zoneId = localStorage.getItem('zoneId');
  useEffect(() => {
    Axios.get(url,{
       headers: { 'zoneId': zoneId }
    }).then((response)=>{
      if(response.data){
        setData(response.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }, [url,zoneId]);

  return [data];
};

export default UseRestaurantReviews;