import { useState, useEffect } from "react";
import Axios from "axios";

const UsePopularRestaurant = (url) => {
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem('userToken');
  const zoneId = localStorage.getItem('zoneId');
  useEffect(() => {
    Axios.get(url,{
      headers : { 'zoneId': zoneId }
    }).then((response)=>{
      if(response.data){
        setData(response.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }, [url,userToken,zoneId]);
  return [data];
};

export default UsePopularRestaurant;


