import { useState, useEffect } from "react";
import Axios from "axios";

const UseProducts = (url) => {
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem('userToken');
  const zoneId = localStorage.getItem('zoneId');
  useEffect(() => {
    Axios.get(url,{
      headers : { 
        'Authorization' : 'Bearer '+userToken,
        'zoneId': zoneId
      }
    }).then((response)=>{
      if(response.data.products){
        setData(response.data.products);
      }
    }).catch((error) => {
      console.log(error)
    });
  }, [url,userToken,zoneId]);
  return [data];
};

export default UseProducts;


