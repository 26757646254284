import { Link,useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants"; 
import Axios from "axios";
import { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { toast } from 'react-toastify';
import $ from 'jquery'; 

export default function ForgetPasswordComponent(){
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit,setValue,setError, formState: { errors } } = useForm();
  const [phone, setPhone] = useState("");
  const [submit,setSubmit] =useState(0);

  const onSubmit = (data) => {
    $('.spinner').css({'display':'inline-flex'});
    Axios.post(Constants.FORGET_PASS_URL,{
      phone: '+'+data.phone
    }).then((response) => {
      if(response.data){
        if(response.data.otp){
          localStorage.setItem('otp',response.data.otp);
          localStorage.setItem('phone','+'+data.phone);
        } 
        navigate('/resetpassword',{state:{notify:true,success:true,message:t(response.data.message)}});
      }
    }).catch((errors) => {
      if(errors){
        if(typeof errors.response.data.errors === 'object'){
          var Arr = errors.response.data.errors;
          for (var i = 0; i < Arr.length; i++) { 
            if(Arr[i].code!=='' && Arr[i].message!==''){
              var message = Arr[i].message.charAt(0).toUpperCase() + Arr[i].message.slice(1);
              toast.error(message); 
            }
          }
        }
      }
    }).finally(() => {
      $('.spinner').css({'display':'none'});
    });
  }

  if(submit){
    if(phone.length>0 || phone==='') setValue('phone',phone);
    if(errors.phone?.type==="required" || phone.length===0) setError("phone", { type: "required" });
    setSubmit(0);
  }


  return(
    <div id="register_bg">
      <div id="register">
        <aside>
          <figure>
            <Link to="/"><img src={localStorage.getItem('logo')} width="140" height="35" alt="Logo" /></Link>
          </figure>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="form-group">
                <label>{t('mobile_no')}</label>
                <input {...register("phone",{required: true})} className="form-control" value={phone || ''} type="hidden" placeholder={t('enter_your_mobile_no')} />
                <PhoneInput  placeholder={t('enter_your_mobile_no')} enableSearch={true} country={Constants.DEFAULT_PHONE_CODE} value={phone}  onChange={(phone) => setPhone(phone)} />
                {(errors.phone?.type==="required" && phone.length===0) && <p className="error">{t('mobile_no_is_required')}</p>}
                {phone.length > 14 && <p className="error">{t('mobile_no_is_maxlength')}</p>}
                {(phone.length > 0 && phone.length < 11) && <p className="error">{t('mobile_no_is_minlength')}</p>}
              </div>
              <button onClick={() => setSubmit(1)} type="submit" className="btn_1 gradient full-width  mt-4"><span className="spinner"></span>&nbsp;&nbsp;{t('forget_password')}</button>
              <div className="text-center mt-2"><small>{t('dont_have_an_account')} <strong><Link to="/register">{t('sign_up')}</Link></strong></small></div>
              <div className="text-center mt-2 ft-12"><span><strong><Link to="/">&lt;&lt; {t('back_to_home')}</Link></strong></span></div>
            </form>
        </aside>
      </div>
    </div>
    )
}