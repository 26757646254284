import React ,{ useEffect,useState } from "react";
import ContentLoader from 'react-content-loader';
import Responsive from "../services/Responsive";

function ListRestaurantLoader({noofList,device}){
  const [config,setConfig] = useState({noofList:2,device:'desktop'});
  useEffect(() => { setConfig({noofList:noofList,device:device}) },[noofList,device]);
  return( 
  <ContentLoader width="100%" height="100%" viewBox={Responsive.RESTAURANT_SIZE[device]} backgroundColor="#f5f5f5" foregroundColor="#dbdbdb">
  {[...Array(config.noofList)].map((arr, i) => {
    i+=1; 
    return (
    <>
    <rect key={i==1 ? 1 : 11} x={Responsive.RESTAURANT_DATA[device][i].x1} y={Responsive.RESTAURANT_DATA[device][i].y1} rx={Responsive.RESTAURANT_DATA[device][i].rx1} ry={Responsive.RESTAURANT_DATA[device][i].ry1} width={Responsive.RESTAURANT_DATA[device][i].w1} height={Responsive.RESTAURANT_DATA[device][i].h1} />
    <rect key={i==1 ? 2 : 12} x={Responsive.RESTAURANT_DATA[device][i].x2} y={Responsive.RESTAURANT_DATA[device][i].y2} rx={Responsive.RESTAURANT_DATA[device][i].rx2} ry={Responsive.RESTAURANT_DATA[device][i].ry2} width={Responsive.RESTAURANT_DATA[device][i].w2} height={Responsive.RESTAURANT_DATA[device][i].h2} />
    <rect key={i==1 ? 3 : 13} x={Responsive.RESTAURANT_DATA[device][i].x3} y={Responsive.RESTAURANT_DATA[device][i].y3} rx={Responsive.RESTAURANT_DATA[device][i].rx3} ry={Responsive.RESTAURANT_DATA[device][i].ry3} width={Responsive.RESTAURANT_DATA[device][i].w3} height={Responsive.RESTAURANT_DATA[device][i].h3} />
    <rect key={i==1 ? 4 : 14} x={Responsive.RESTAURANT_DATA[device][i].x4} y={Responsive.RESTAURANT_DATA[device][i].y4} rx={Responsive.RESTAURANT_DATA[device][i].rx4} ry={Responsive.RESTAURANT_DATA[device][i].ry4} width={Responsive.RESTAURANT_DATA[device][i].w4} height={Responsive.RESTAURANT_DATA[device][i].h4} />
    <rect key={i==1 ? 5 : 15} x={Responsive.RESTAURANT_DATA[device][i].x5} y={Responsive.RESTAURANT_DATA[device][i].y5} rx={Responsive.RESTAURANT_DATA[device][i].rx5} ry={Responsive.RESTAURANT_DATA[device][i].ry5} width={Responsive.RESTAURANT_DATA[device][i].w5} height={Responsive.RESTAURANT_DATA[device][i].h5} />
    <rect key={i==1 ? 6 : 16} x={Responsive.RESTAURANT_DATA[device][i].x6} y={Responsive.RESTAURANT_DATA[device][i].y6} rx={Responsive.RESTAURANT_DATA[device][i].rx6} ry={Responsive.RESTAURANT_DATA[device][i].ry6} width={Responsive.RESTAURANT_DATA[device][i].w6} height={Responsive.RESTAURANT_DATA[device][i].h6} />
    <rect key={i==1 ? 7 : 17} x={Responsive.RESTAURANT_DATA[device][i].x7} y={Responsive.RESTAURANT_DATA[device][i].y7} rx={Responsive.RESTAURANT_DATA[device][i].rx7} ry={Responsive.RESTAURANT_DATA[device][i].ry7} width={Responsive.RESTAURANT_DATA[device][i].w7} height={Responsive.RESTAURANT_DATA[device][i].h7} />
    <rect key={i==1 ? 8 : 18} x={Responsive.RESTAURANT_DATA[device][i].x8} y={Responsive.RESTAURANT_DATA[device][i].y8} rx={Responsive.RESTAURANT_DATA[device][i].rx8} ry={Responsive.RESTAURANT_DATA[device][i].ry8} width={Responsive.RESTAURANT_DATA[device][i].w8} height={Responsive.RESTAURANT_DATA[device][i].h8} />
    <rect key={i==1 ? 9 : 19} x={Responsive.RESTAURANT_DATA[device][i].x9} y={Responsive.RESTAURANT_DATA[device][i].y9} rx={Responsive.RESTAURANT_DATA[device][i].rx9} ry={Responsive.RESTAURANT_DATA[device][i].ry9} width={Responsive.RESTAURANT_DATA[device][i].w9} height={Responsive.RESTAURANT_DATA[device][i].h9} />
    </>
    )
  })}
  </ContentLoader>
)

}
export default ListRestaurantLoader;