import '../assets/css/error.css';


export default function ErrorComponent(){
	return(
		<main className="bg_gray">
			<div id="error_page">
				<div className="container">
					<div className="row justify-content-center text-center">
						<div className="col-xl-7 col-lg-9">
							<figure><img src={require('../assets/images/404.svg').default} alt="" className="img-fluid" width="550" height="234" /></figure>
							<p>We're sorry, but the page you were looking for doesn't exist.</p>
							<form method="post" action="grid-listing-filterscol.html">
	            </form>
						</div>
					</div>
				</div>
			</div>
		</main>
	)
}