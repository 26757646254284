import { TailSpin } from 'react-loader-spinner'
import React, { useRef,useState } from "react";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { toast } from 'react-toastify';
import { StandaloneSearchBox,useJsApiLoader } from "@react-google-maps/api";
import Constants from "../services/Constants";
import UseAddress from "../hook/UseAddress";
import IframeComponent from "../views/iframe";
import { useForm } from "react-hook-form";
import Axios from "axios";
import $ from 'jquery';


export default function MyAddressComponent(){
  const inputRef = useRef();
  const [loading,setLoading] = useState(0);
  const [update,setUpdate] = useState(0);
  const [addresses] = UseAddress(Constants.GETADDRESS_URL,update);
  const userToken = localStorage.getItem('userToken');
  const { register, handleSubmit,setValue,reset, formState: { errors } } = useForm();
  const { t } = useTranslation();
  const [addressloc ,setAddressLoc] = useState({lat:10.00000,lng:78.00000,zoom:10});
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Constants.REACT_APP_GOOGLE_API_KEY,
    libraries: ['geometry', 'drawing','places'],
  });

  const showConfirm = (id) => {
    $.confirm({
      icon: 'fa fa-trash',theme: 'modern',
      scrollToPreviousElement: false,
      useBootstrap: false,boxWidth: '35%',
      title: 'Your address will be deleted permanently!',
      content: 'Are you sure to proceed?',
      closeIcon: true,draggable: false,animation: 'scale',
      type: 'red',
      buttons: {
        Ok: {
          text: 'Ok',btnClass: 'btn-red',
          action: function(){
            Axios.delete(Constants.DELADDRESS_URL, { data: { address_id:id }, 
              headers: { 'Authorization': 'Bearer '+userToken } 
            }).then((response) => {
                if(response.data.message)
                  toast.success(response.data.message);
            }).catch((error) => {
              console.log(error);
            }).finally((error) => {
              setUpdate(update+1);
            })
          }
        },
        close: function () { }
      }
    });
  }

  
  const onSubmit = (data) => {
    Axios.post(Constants.ADD_ADDRESS_URL,{
      address:data.address,longitude:addressloc.lng,
      latitude:addressloc.lat,contact_person_name:data.name,
      road:data.road,house:data.house,floor:data.floor,
      address_type:data.address_type,contact_person_number:data.mobile
    },{
      headers : { 'Authorization' : 'Bearer  '+localStorage.getItem('userToken') }
    }).then((response) => {
      if(response.data)
      toast.success(response.data.message);
    }).catch((error) => {
      console.log('error',error);
    }).finally((res) => {
      reset();
      setUpdate(update+1);
    });
  }
    

  const handlePlaceChanged = () => { 
    const [ place ] = inputRef.current.getPlaces();
    if(place) {  
      if(place.geometry.location.lat() && place.geometry.location.lng())
      setAddressLoc({...addressloc,lat:place.geometry.location.lat(),lng:place.geometry.location.lng(),zoom:20});
    } 
  }

  const convertDateString = (date) => {
    let ChangeString = new Date(date);
    return ChangeString.toDateString();
  }


  useEffect(() => {
    setTimeout(() => setLoading(1),Constants.LOADING_SECONDS);
  },[addresses])

  useEffect(() => { setUpdate(1)},[update])


  return (
    <>
     {!loading &&
      <div className="container content-center height-full">
        <TailSpin height="50" width="50" color="#f3723b" ariaLabel="tail-spin-loading"
        radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
      </div>}
    {loading===1 && 
      <>
      <div className="container margin_60_20 margin-30">
        <div className="row">
          <div className="col-md-12">
            <div className="box_order_form">
              <div className="head">
                <h3>{t('add_address')}</h3>
              </div>
              <div className="main">
                <form id="customer-form" onSubmit={handleSubmit(onSubmit)}>  
                <div className="row">    
                <div className="col-md-6">   
                <div className="form-group col-md-12 mt-2">
                  <label>{t('name')}<span className="required"> *</span></label>
                  <input 
                  {...register("name", 
                    { 
                      required: true, 
                    }
                  )}
                  className="form-control" type="text" placeholder={t('enter_your_name')} />
                  {errors.name?.type==="required" && <p className="error">{t('name_is_required')}</p>}
                </div>
                <div className="form-group col-sm-12 mt-2">
                  <label>{t('mobile')}<span className="required"> *</span></label>      
                  <input
                  {...register("mobile", 
                    { 
                      required: true, 
                      onChange :(e) => setValue('phone',e.target.value.replace(/\D/g,'')),
                    }
                  )}
                  placeholder={t('enter_your_mobile_no')} className="form-control" type="text"  />     
                  {errors.mobile?.type==="required" && <p className="error">{t('mobile_is_required')}</p>}
                </div>
                <div className="form-group col-md-12 mt-2">
                  <label>{t('floor')}</label>
                  <input 
                  {...register("floor")}
                  className="form-control" type="text" placeholder={t('enter_your_floor')} />
                  {errors.floor?.type==="required" && <p className="error">{t('floor_is_required')}</p>}
                </div>
                <div className="form-group col-sm-12 mt-2">
                  <label>{t('road')}</label>      
                  <input
                  {...register("road")}
                  placeholder={t('enter_your_road')} className="form-control" type="text"  />     
                  {errors.road?.type==="required" && <p className="error">{t('road_is_required')}</p>}
                </div>
                <div className="form-group col-sm-12 mt-2">
                  <label>{t('house')}</label>      
                  <input
                  {...register("house")}
                  placeholder={t('enter_your_house')} className="form-control" type="text"  />     
                  {errors.house?.type==="required" && <p className="error">{t('house_is_required')}</p>}
                </div>
                <div className="form-group col-md-12 mt-2">
                <label>{t('address')}<span className="required"> *</span></label>
                {isLoaded &&
                  <StandaloneSearchBox
                    onLoad={ref => inputRef.current = ref}
                    onPlacesChanged={handlePlaceChanged} >
                    <input 
                      {...register("address", 
                        { 
                          required: true
                        }
                      )}
                      type="text"
                      className="form-control no_border_r"
                      name="address"
                      placeholder={t('enter_your_address')}
                    />
                 </StandaloneSearchBox>}
                  {errors.address?.type==="required" && <p className="error">{t('address_is_required')}</p>}
                </div>
                <div className="form-group col-md-12 mt-2">
                  <label>{t('address_type')}<span className="required"> *</span></label>&nbsp;
                  {Constants.ADDRESS_TYPES.length > 0 &&
                    Constants.ADDRESS_TYPES.map((address_type,index) => {
                      return (
                        <span key={index+1} className="d-inlineblock">
                        <input 
                        {...register("address_type", 
                          { 
                            required: true, 
                          }
                        )} value={address_type} type="radio" />
                        &nbsp;<label>{t(address_type)}</label>&nbsp;
                        </span>
                      )
                    })
                  }
                {errors.address_type?.type==="required" && <p className="error">{t('address_type_is_required')}</p>}
                </div>
                  <div className="form-group col-md-12 mt-2">
                    <button type="submit" className="btn_1 gradient full-width" >{t('update')}</button>
                  </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <IframeComponent lat={addressloc.lat} lng={addressloc.lng} zoom={addressloc.zoom} />
                    </div>
                  </div>
                </form>     
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container margin_60_20 margin-30">
        <div className="row">
          <div className="col-md-12">
            <div className="box_order_form">
              <div className="head">
                <h3>{t('address_book')}</h3>
              </div>
              <div className="main">
                <div className="row add_bottom_25">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <div className="list_home">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <td>{t('address_type')}</td>
                              <td>{t('full_address')}</td>
                              <td>{t('added_on')}</td>
                              <td>{t('action')}</td>
                            </tr>
                          </thead>
                          <tbody>
                          {addresses.length > 0 &&
                            addresses.map((address,index) => {
                              return(
                                <tr key={index+1}>
                                 <td>{address.address_type.charAt(0).toUpperCase() + address.address_type.slice(1)}</td>
                                  <td>{address.address}</td>
                                  <td>{convertDateString(address.created_at)}</td>
                                  <td><i onClick={() => showConfirm(address.id)} className="cursor icon_trash_alt"></i></td>
                                </tr>
                          )})}
                          {addresses.length===0 && <tr><td colSpan="4"><h6>No result Found</h6></td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>}
    </>
  )
}
