import { TailSpin } from 'react-loader-spinner'
import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import Constants from "../services/Constants";
import UseOrders from "../hook/UseOrders";
import PaginationComponent from "../components/PaginationComponent";

export default function MyOrderHistoryComponent({openPage}){
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading,setLoading] = useState(0);
  const webConfig = JSON.parse(localStorage.getItem('web'));
  const [currentPage, setCurrentPage] = useState(1);
  const {total_size,orders} = UseOrders(Constants.GETORDERS_API_URL+'?limit=10&offset='+currentPage);
  const currencySymbol = webConfig ? (webConfig.currency_symbol!=='' ? webConfig.currency_symbol : '') : '';
  const currencySymbolDir = webConfig ? (webConfig.currency_symbol_direction!=='' ? webConfig.currency_symbol_direction : '') : '';

  useEffect(() => {
      setTimeout(() => setLoading(1),Constants.LOADING_SECONDS);
  },[loading,orders.length]);

  const convertDateString = (date) => {
    let ChangeString = new Date(date);
    return ChangeString.toDateString();
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  } 

  return (
    <main>
      {loading===0 &&
        <div className="container content-center height-full">
          <TailSpin height="50" width="50" color="#f3723b" ariaLabel="tail-spin-loading"
          radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
        </div>}
      {loading===1 && 
        <>
        <div className="container margin_60_20 margin-30">
          <div className="row">
            <div className="col-md-12">
             <div className="box_order_form">
              <div className="head">
               <h3>{t('my_orders')}</h3>
              </div>
              <div className="main">
                <div className="row add_bottom_25">
                  <button  className="tablink" id="tablink-running" onClick={() => navigate('/orders')}>{t('running_orders')}</button>
                  <button style={{'backgroundColor':'#777'}} className="tablink" id="tablink-history" onClick ={() => openPage('history')}>{t('history')}</button>
                <div id="running" className="review_card col-md-12" style={{'display':'none'}}>
                  <div className="table-responsive">
                    <div className="list_home">
                      <table className="table table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <td>{t('order_id')} & {t('date')}</td>
                            <td>{t('restaurant')}</td>
                            <td>{t('delivery_address')}</td>
                            <td>{t('total')}</td>
                            <td>{t('status')}</td>
                            <td className="width-15">{t('order_status')}</td>
                          </tr>
                        </thead>
                        <tbody>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                <div id="history" className="review_card col-md-12" >
                  <div className="table-responsive">
                    <div className="list_home">
                      <table className="table table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <td>{t('order_id')} & {t('date')}</td>
                            <td>{t('restaurant')}</td>
                            <td>{t('delivery_address')}</td>
                            <td>{t('total')}</td>
                            <td>{t('status')}</td>
                            <td className="width-15">{t('order_status')}</td>
                          </tr>
                        </thead>
                        <tbody>
                        {orders.length > 0 &&
                          orders.map((order,index) => {
                            return(
                              <tr key={index+1}>
                                <td>
                                <Link to={`/orderdetail/${order.id}/${window.btoa(order.order_status)}`}>#{order.id}</Link><br/>
                                {convertDateString(order.created_at)}</td>
                                {order.restaurant ? <td>{order.restaurant.name}</td> : <td></td>}
                                <td>{order.delivery_address ? order.delivery_address.address : ''}</td>
                                <td>{currencySymbolDir==="left" ? currencySymbol : ""} {order.order_amount} {currencySymbolDir==="right" ? currencySymbol : ""}</td>
                                <td>{order.payment_status.charAt(0).toUpperCase() + order.payment_status.slice(1) +':'
                                +(currencySymbolDir==="left" ? currencySymbol : "")+" "+order.order_amount+" "+(currencySymbolDir==="right" ? currencySymbol : "")}<br/>
                                By {order.payment_method==='cash_on_delivery' ? 'Cash On Delivery' : 'Digital Payment'}</td>
                                <td>
                                  <span className={`badge badge-${Constants.STATUS_COLOR[order.order_status]}`}>{order.order_status.charAt(0).toUpperCase() + order.order_status.slice(1)}</span>
                                  <br />
                                  <br />
                                  <Link className="btn-sm btn-primary" to={`/orderdetail/${order.id}/${window.btoa(order.order_status)}`} >Detail</Link>
                                  <br />
                                  <br />
                                  {order.order_status!=='delivered' &&
                                  <Link to={`/trackorder/${order.id}`} className="btn btn-danger ft-12">{t('track_order')}
                                  </Link>}
                                </td>
                              </tr>
                            )})}
                            {orders.length===0 && <tr><td colSpan="6"><h6>No result Found</h6></td></tr>}
                          </tbody>
                        </table>
                         {total_size > 10 && <PaginationComponent currentPage={currentPage} postsPerPage={10}
                           totalPosts={total_size} paginate={paginate} page={'ordershistory'} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    }
  </main>
  )
}